// notification.model.ts
export interface Notification {
    id: string;
    createDate: Date;
    senderDate: string;
    expirationDate:any;
    sender: string;
    receiver: string;
    notificationMethod: NotificationMethod;
    status: NotificationStatus;
    title: string;
    message: string;
    scheduled?: boolean;
    alert?: boolean;
    notificationType: NotificationType;
    validityPeriod:any;
    destinationId:string;
    platforms:any[];
  }
  
  export enum NotificationType {
    FEEDBACK = 'FEEDBACK',
    ALERT_ROUTE = 'ALERT_ROUTE',
    CLIENT_AD = 'CLIENT_AD'
  }
  
  export enum NotificationMethod {
    PUSH = 'PUSH',
    EMAIL = 'EMAIL'
  }
  
  export enum NotificationStatus {
    PENDING = 'PENDING',
    SENT = 'SENT',
    READ = 'READ',
    ERROR = 'ERROR'
  }
  