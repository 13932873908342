import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { HttpClientService } from '../../../service/httpclient.service'
import { Router, ActivatedRoute } from "@angular/router";
import { TokenService } from '../../../service/token.service';
import { HttpParams } from '@angular/common/http';
import { of } from 'rxjs';
import { MicrositeRoute } from 'src/app/models/Microsite.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileService } from 'src/app/service/file.service';
import { Route } from 'src/app/models/Route.model';
import { SpinnerService } from 'src/app/service/SpinnerService';
import { TopicService } from 'src/app/service/topic.service';

const API_DESTINATION = 'api/destination/'
const API_TOPIC = "api/topic/"
const API_POINT = "api/point/"
const API_MICROSITE = 'api/microsite/'
const API_ROUTE = "api/route/"

@Component({
  selector: 'app-route-detail',
  templateUrl: './route-detail.component.html',
  styleUrls: ['./route-detail.component.scss']
})
export class RouteDetailComponent implements OnInit {
  platformNames = new Set<string>();
  platformGroups: any[] = [];  // Contiene el array de { platform, topics }
  // Por ejemplo, usando el índice de cada leg
// Inicialización con un registro por defecto para índice 0
legDateTime: { [index: number]: { date: string; time: string } } = {
  0: { date: '', time: '' }
};

  geoJsonBase64;
  previewBase64;
  qrBase64;
  pdfBase64;
  imageBase64;

  legs = [{ points: [], expectedDate: null, distanceInMeters: 0, durationInMinutes: 0 }];
  topics: any[] = [{ parent: {}, kids: [] }];
  localTopics = [];
  filterText = "";
  points = [];
  ownerDestinations = [];
  destination;
  destinationId;
  routeId;
  micrisite: MicrositeRoute = new MicrositeRoute();
  route: Route = new Route();

  // route = {micrositeRoute:this.micrisite, legs: [], topics: [], destinations: [], verified: false, visible: false, isPreconfigured: false, hasEvents: false, isMicrosite:false };
  selectedPoints = [];
  panelOpenState = false;
  topicsFilter = [];
  currentUrl;
  comments = false;
  destinations = [];
  isDetail = false;
  pdfUrl;
  geojsonUrl;
  constructor(private http: HttpClientService, private spinner: SpinnerService,
    private _snackBar: MatSnackBar, private topicService: TopicService,
    private activeRoute: ActivatedRoute,
    public tokenService: TokenService, private fileService: FileService,
    public router: Router) { }

  async ngOnInit() {

    this.route.micrositeRoute = this.micrisite;
    console.log(this.route);
    this.currentUrl = document.URL.replace(/#.*$/, "");
    await this.findTopics();
    this.destination = this.tokenService.getDestination();
    console.log("Destination", this.destination);
    if (this.tokenService.isAdmin()) {
      this.findDestinations();
    }
    this.activeRoute.paramMap.subscribe(params => {
      if (params.get('id') != null && (params.get('idRoute') == null)) {
        this.destinationId = params.get('id');
        this.spinner.showSpinner();
        this.findPoints();
        this.findDestinationById();

      } else if (params.get('id') != null && params.get('idRoute') != null) {
        this.spinner.showSpinner();
        this.destinationId = params.get('id');
        this.routeId = params.get('idRoute');
        this.isDetail = true;
        this.findPoints();
        this.findDestinationById();
        this.comments = true;
      }
    });
  }

  addDay() {
    this.legs.push({ points: [], expectedDate: null, distanceInMeters: 0, durationInMinutes: 0 });
  }

  findDestinations() {
    this.http.get(API_DESTINATION).subscribe(result => {
      this.destinations = result;
    })
  }


  findRoute() {


    this.http.get(API_ROUTE + 'admin/' + this.routeId).subscribe(data => {
      this.route = data;
        this.initializeLegDateTime(this.route.legs);
      if (this.route.micrositeRoute != null) {
        if (this.route.micrositeRoute.pdfInfographic != null) {
          this.pdfUrl = 'data:application/pdf;base64,' + this.route.micrositeRoute.pdfInfographic;
        }
        if (this.route.micrositeRoute.geoJson != null) {
          this.geojsonUrl = 'data:application/json;base64,' + this.route.micrositeRoute.geoJson;

        }
      }
      if (data.legs && data.legs.length > 0) {
        data.legs.forEach((leg, i) => {
          if (leg.points && leg.points.length > 0) {
            this.route.legs[i].points = leg.points.map(secPoint => {
              let aux = this.points.find(point => point.id === secPoint.pointId);
              return aux || secPoint; // Usa aux si se encuentra, de lo contrario, conserva secPoint
            });
          }
        });
      }

      this.legs = this.route.legs;
      this.spinner.hideSpinner();
      this.setTopics();
    })
  }

  delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  setTopics() {

    if (this.route.topics != null && this.route.topics.length > 0) {
      this.topics.forEach(topic => {
        if (this.route.topics.includes(topic.parent.id)) {
          topic.value = true;
          this.topicsFilter.push(topic.parent.id); // Añade al filtro

        }
        if (topic.kids.length > 0) {
          topic.kids.forEach(kid => {
            if (this.route.topics.includes(kid.topic.id)) {
              kid.value = true;
              this.topicsFilter.push(kid.topic.id); // Añade al filtro

            }
          })
        }

      })
      this.localTopics.forEach(topic => {
        if (this.route.topics.includes(topic.id)) {
          topic.value = true;
          this.topicsFilter.push(topic.id); // Añade al filtro

        }
      })
    }

    console.log("después de set", this.topics, "Topics Filter:", this.topicsFilter);
  }

  findPoint(pointId) {
    return this.http.get(API_POINT + pointId);
  }


  async findTopics(): Promise<void> {
    try {
      const result = await this.topicService.getAllTopics().toPromise();
      const allTopics = result;

      this.localTopics = allTopics.filter(t => t.local === true);
      console.log("LOCALTOPICS:", this.localTopics);

      // Para obtener los temas no locales, se puede usar simplemente !t.local
      const nonLocalTopics = allTopics.filter(t => !t.local);
      this.platformGroups = this.topicService.groupByPlatformWithHierarchy(nonLocalTopics);

      // Crear los temas padres (sin padre y no locales)
      const topics = result;
      const parents = topics.filter(topic => topic.parentId == null && !topic.local)
        .map(topic => ({ parent: topic, value: false, kids: [] }));

      // Para cada tema padre, asignar sus temas hijos
      parents.forEach(parent => {
        parent.kids = result
          .filter(topic => topic.local == null || topic.local === false)
          .filter(topic => !topic.local)
          .filter(topicChill => topicChill.parentId === parent.parent.id)
          .map(topic => ({ topic, value: false }));
      });

      this.topics = parents;
    } catch (error) {
      console.error('Error al obtener los topics:', error);
    }
  }



  topicCheck(object: any) {
    this.topicsSelected(object.event, object.topic);
  }

  topicsSelected(event: any, topic) {
    if (event.checked) {
      if (this.topicsFilter == null) {
        this.topicsFilter = [];
      }
      this.topicsFilter.push(topic);
    } else {
      this.topicsFilter = this.topicsFilter.filter(t => t != topic);
    }
    console.log(this.topicsFilter)
  }

  addPoints(legIndex) {
    this.panelOpenState = false;
    this.selectedPoints.forEach((point) => this.legs[legIndex].points.push(point));
    this.selectedPoints = [];

  }

  findPoints() {
    let searchName = '';
    let topicsFilter = '';
    let params = new HttpParams()
      .set('name', searchName.toString())
      .set('topics', topicsFilter.toString())
    this.http.get(API_POINT + 'destination/' + this.destinationId, params).subscribe(result => {
      this.points = result;
      this.spinner.hideSpinner();
      if (this.isDetail) {
        this.findRoute();
      }
    });
  }

  drop(event: CdkDragDrop<string[]>, legIndex) {
    moveItemInArray(this.legs[legIndex].points, event.previousIndex, event.currentIndex);
  }

  deletePoint(legIndex, pintIndex) {
    this.legs[legIndex].points.splice(pintIndex, 1);
  }

  deleteDay(legIndex: number): void {
    if (legIndex > 0 && legIndex < this.legs.length) {
      this.legs.splice(legIndex, 1);
    }
  }

  onSelectionChange(legIndex: number, point: any) {
    this.legs[legIndex].points.push(point);
  }

  save() {
    let saveRoute = this.route;
    if (saveRoute.destinations == null || saveRoute.destinations.length == 0) {
      saveRoute.destinations = new Array(this.destinationId);
    } else if (!saveRoute.destinations.includes(this.destinationId)) {
      saveRoute.destinations.push(this.destinationId);
    }
    saveRoute.destinations = saveRoute.destinations.filter(dest => dest !== null);
    console.log(this.legs)
    this.legs.forEach(leg => {
      leg.points = leg.points.map(point => {
        return { pointId: point.id }
      });
    });
    saveRoute.legs = this.legs;
    saveRoute.topics = this.topicsFilter.filter(t => t !== null);
    this.http.post(API_ROUTE, saveRoute).subscribe(data => {
      console.log(data);
      this.openSnackBar("Guardado correctamente", "Cerrar");
      this.goBack();
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000
    });
  }

  goBack() {
    if (this.tokenService.isAdmin()) {
      this.router.navigate(['/admin/route/']);
    } else {
      this.router.navigate(['/destination/detail/' + this.destinationId + '/route']);
    }
  }

  findDestinationById() {

    this.http.get(API_DESTINATION + this.destinationId).subscribe(result => {
      this.destination = result;
      this.ownerDestinations.push(result);
      result.platforms.forEach(platform => {
        this.platformNames.add(platform.name);
      });
      this.platformGroups = this.platformGroups.filter(group => {
        return this.platformNames.has(group.platform.name);
      });
      let destTopicsId = [];
      this.ownerDestinations.forEach(des => destTopicsId = destTopicsId.concat(des.topics));

      this.localTopics = this.localTopics
        .filter(t => destTopicsId.some(id => id === t.id))
        .sort((a, b) => a.name.localeCompare(b.name));
    })
  }

  async addDestinationOwner(destinationId) {
    // if (this.route.destinations == null || this.route.destinations.length == 0) {
    //   this.route.destinations = new Array(destinationId);
    // } else if (!this.route.destinations.includes(destinationId)) {
    //   this.route.destinations.push(this.destinationId);
    // }
    // let destination = this.destinations.find(dest => dest.id === destinationId);
    // this.ownerDestinations.push({ name: destination.name, usersEmails: ['Sin guardar'] })
    this.destinationId = destinationId;
    this.findPoints();
    await this.findTopics();
    this.findDestinationById();
  }

  filterGroupByPlatform() {
    this.platformGroups = this.platformGroups.filter(group => {
      return this.platformNames.has(group.platform.name);
    });
  }


  deleteDestination(index: number): void {
    this.ownerDestinations.splice(index, 1);  // Elimina el elemento en el índice dado
    this.findTopics();
  }

  isMicrosite() {
    if (this.route.isMicrosite) {
      this.route.visible = false;
    }
  }


  onImageSelected(event: any, type: number) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      const MAX_SIZE = 5 * 1024 * 1024; // Tamaño máximo de 5 MB

      if (file.size > MAX_SIZE) {
        this._snackBar.open('El archivo es demasiado grande. Tamaño máximo permitido: 5 MB.', 'Cerrar', {
          duration: 3000,
        });
        return; // Detiene la ejecución si el archivo es demasiado grande
      }

      reader.onload = async (e: ProgressEvent) => {
        const target = e.target as FileReader; // Aserción de tipo aquí
        const resizedBase64 = await this.fileService.resizeImage(target.result as string, 800, 800, 0.7); // Redimensionar y comprimir
        if (type == 1) {
          this.route.micrositeRoute.image = resizedBase64;
        } else if (type == 2) {
          this.route.micrositeRoute.previewInfographic = resizedBase64;
        } else if (type == 3) {
          this.route.micrositeRoute.qr = resizedBase64;
        }
      };
      reader.readAsDataURL(file);
      event.target.value = '';
    }
  }

  onFileSelected(event: any, fileType: string) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      const MAX_SIZE = 10 * 1024 * 1024; // Por ejemplo, tamaño máximo de 10 MB para PDF o GeoJSON.

      if (file.size > MAX_SIZE) {
        this._snackBar.open('El archivo es demasiado grande. Tamaño máximo permitido: 10 MB.', 'Cerrar', {
          duration: 3000,
        });
        return;
      }

      reader.onload = (e: ProgressEvent) => {
        const target = e.target as FileReader;
        const base64File = target.result as string;
        if (fileType === 'pdf') {
          this.route.micrositeRoute.pdfInfographic = base64File;
        } else if (fileType === 'geojson') {
          this.route.micrositeRoute.geoJson = base64File;
        }
      };

      reader.readAsDataURL(file); // Lee el archivo como Base64
      event.target.value = ''; // Reiniciar el campo de selección
    }
  }

  deleteImage(id, type: string) {
    this.http.deleteById(`${API_MICROSITE}route/${id}/type/${type}`).subscribe(result => {
      this.route = result;
    });
  }

  downloadFile(base64Data: string, fileType: 'pdf' | 'geojson') {
    const byteCharacters = atob(base64Data.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Definir el tipo de contenido basado en el tipo de archivo
    let blob;
    let fileName;
    if (fileType === 'pdf') {
      blob = new Blob([byteArray], { type: 'application/pdf' });
      fileName = 'document.pdf';
    } else if (fileType === 'geojson') {
      blob = new Blob([byteArray], { type: 'application/vnd.geo+json' });
      fileName = 'map.geojson';
    }

    // Crear una URL para el Blob y simular la descarga
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = fileName;
    link.click();

    // Liberar el Blob después de su uso
    URL.revokeObjectURL(blobUrl);
  }
  // Supongamos que 'legs' es un array de objetos, cada uno representando un día (leg)
  // y que cada objeto puede tener las propiedades: date, time y expectedDate.
  updateExpectedDate(leg: any, index: number): void {
    const dt = this.legDateTime[index];
    if (dt && dt.date && dt.time) {
      const [hours, ] = dt.time.split(':').map(Number); // Ignoramos los minutos
      const updatedDate = new Date(dt.date);
      updatedDate.setHours(hours, 0, 0, 0);
      // Formateamos la fecha en el patrón requerido "yyyy-MM-dd,HH:00"
      const formattedDate = updatedDate.toISOString().split('T')[0] + ',' + 
        updatedDate.getHours().toString().padStart(2, '0') + ':00';
      leg.expectedDate = formattedDate;
    }
  }
  


  initializeLegDateTime(legs: any[]): void {


    legs.forEach((leg, index) => {
      if (leg.expectedDate) {
        const dateObj = new Date(leg.expectedDate);
        this.legDateTime[index] = {
          date: dateObj.toISOString().split('T')[0], // "YYYY-MM-DD"
          time: dateObj.toTimeString().split(' ')[0].slice(0, 5) // "HH:mm"
        };
      } else {
        this.legDateTime[index] = { date: '', time: '' };
      }
    });
  }



}
