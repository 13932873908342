import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { TokenService } from '../service/token.service';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
const TOKEN_KEY = 'AuthToken';
const USERNAME_KEY = 'AuthUserName';
const AUTHORITIES_KEY = 'AutAuthorities';
@Injectable({
  providedIn: 'root'
})

export class BasicAuthHtppInterceptorService implements HttpInterceptor {
  constructor(private tokenService: TokenService, private router: Router) { }
  intercept(req: HttpRequest<any>, next: HttpHandler) {

    let autReq = req;
    const token = this.tokenService.getToken();
    if (token != null) {
      autReq = req.clone({
        setHeaders: {
          Authorization: 'Bearer ' + sessionStorage.getItem(TOKEN_KEY)
        }

      });
    }
    return next.handle(autReq).pipe(tap(() => { },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401) {
            return;
          }
          this.router.navigate(['logout'])
        }
      }
    ));
  }
}