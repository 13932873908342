export class LanguageString {
    lang: string;
    s: string;
  
    constructor(lang: string, s: string) {
      this.lang = lang;
      this.s = s;
    }
  }
  
  export class MicrositeRoute {
    mapsUrl: string;
    linkWikiloc: string;
    geoJson: string;
    qr: string;
    previewInfographic: string;
    pdfInfographic: string;
    image: string;
    otherImages: string[];
    names: LanguageString[];
    description: string;
    descriptions: LanguageString[];
  
    constructor(
      mapsUrl?: string,
      linkWikiloc?: string,
      geoJson?: string,
      qr?: string,
      previewInfographic?: string,
      pdfInfographic?: string,
      image?: string,
      otherImages?: string[],
      names?: LanguageString[],
      description?: string,
      descriptions?: LanguageString[]
    ) {
      this.mapsUrl = mapsUrl || '';
      this.linkWikiloc = linkWikiloc || '';
      this.geoJson = geoJson || null;
      this.qr = qr || null;
      this.previewInfographic = previewInfographic || null;
      this.pdfInfographic = pdfInfographic || null;
      this.image = image || null;
      this.otherImages = otherImages || [];
      this.names = names || [];
      this.description = description || '';
      this.descriptions = descriptions || [];
    }
  }
  