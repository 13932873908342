import { Component, OnInit,Input,  Output, EventEmitter,  OnChanges, SimpleChanges} from '@angular/core';
import { TopicService } from 'src/app/service/topic.service';
import { HttpClientService } from 'src/app/service/httpclient.service';

@Component({
  selector: 'app-topic-filter-list',
  templateUrl: './topic-filter-list.component.html',
  styleUrls: ['./topic-filter-list.component.scss']
})
export class TopicFilterListComponent implements OnInit, OnChanges {
  topics: any[] = [];
  topicfiltered: any[] = [];
  topicNamefiltered: any[] = [];
  names:string="";
  topicsMap = new Map();
  @Output() topicsSelected = new EventEmitter<string>();
  @Input() routeTopics: string[] = [];

  constructor(private topicService: TopicService,  private http: HttpClientService){}

  ngOnInit() {
    this.findTopics();
  }

  findTopics() {
    this.topicService.getGroupTopics().subscribe(result => {
      this.topicService.serAllTopics(result);
      this.topics = result;   this.topicsMap.clear();
      result.forEach(topic => this.topicsMap.set(topic.id, topic.name));
    });
  }


  checkInitialTopics() {
    this.topics.forEach(topic => {
      topic.value = this.routeTopics.includes(topic.parent.id);
      if (topic.kids) {
        topic.kids.forEach(kid => {
          kid.value = this.routeTopics.includes(kid.topic.id);
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["routeTopics"] && this.topics.length > 0) {
      this.checkInitialTopics();
      this.topicfiltered = [...this.routeTopics];
      this.topicNamefiltered= this.topicService.allTopics.filter(t=>this.routeTopics.includes(t.id)).map(t=>t.name);
    }
  }



  topicsFilter(event: any, topic) {
    if (event.checked) {
      if (this.topicfiltered == null) {
        this.topicfiltered = [];
      }
      this.topicfiltered.push(topic.id);
    } else {
      this.topicfiltered = this.topicfiltered.filter(t => t != topic.id);
    }
    this.topicNamefiltered = this.topicService.allTopics.filter(t=>this.topicfiltered.includes(t.id)).map(t=>t.name);
  }


  topicCheck(event: any, topic: any, id: string) {
    if(topic.parent!=null){

      this.topicsFilter(event, topic.parent);
    }else{
      this.topicsFilter(event, topic);
    }
    topic.value = event.checked;
    if (topic.kids) {
      topic.kids.forEach(kid => {
        kid.value = event.checked;
        this.topicsFilter(event, kid.topic); 
        let object:any = {event:event, topic:kid.topic.id}
        this.topicsSelected.emit(object);
      });
    }
    let object:any = {event:event, topic:id}
    this.topicsSelected.emit(object);
  }

}
