import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { AuthGaurdService } from './service/auth-gaurd.service';
import { RecoveryComponent } from './auth/recovery/recovery.component';
import { DestinationComponent } from './client/destination/destination.component';
import { PointComponent } from './client/point/point.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminDestinationComponent } from './admin/admin-destination/admin-destination.component';
import { AdminClientComponent } from './admin/admin-client/admin-client.component';
import { DestinationDetailComponent } from './client/destination/destination-detail/destination-detail.component';
import { PointDetailComponent } from './client/point/point-detail/point-detail.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { RouteDetailComponent } from './client/route/route-detail/route-detail.component';
import { RouteComponent } from './client/route/route.component';
import { AdminTopicComponent } from './admin/admin-topic/admin-topic.component';
import { AdminDestinationDetailComponent } from './admin/admin-destination/admin-destination-detail/admin-destination-detail.component';
import { AdminClientDetailComponent } from './admin/admin-client/admin-client-detail/admin-client-detail.component';
import { AdminTopicDetailComponent } from './admin/admin-topic/admin-topic-detail/admin-topic-detail.component';
import { AdminPointComponent } from './admin/admin-point/admin-point.component';
import { AdminPointDetailComponent } from './admin/admin-point/admin-point-detail/admin-point-detail.component';
import { AdminRouteComponent } from './admin/admin-route/admin-route.component';
import { AdminRouteDetailComponent } from './admin/admin-route/admin-route-detail/admin-route-detail.component';
import { ResetComponent } from './auth/reset/reset.component';
import { NotificationsComponent } from './client/notifications/notifications.component';
import { NotificationsDetailComponent } from './client/notifications/notifications-detail/notifications-detail.component';
import { AdminNotificationComponent } from './admin/admin-notification/admin-notification.component';
const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'reset/:token/:id', component: ResetComponent },
  { path: 'recovery', component: RecoveryComponent },
  // { path: 'notification', component: NotificationsComponent, canActivate: [AuthGaurdService], data: { roles: ["CLIENT", "ADMIN"] } },
  // { path: 'notification/detail', component: NotificationsDetailComponent, canActivate: [AuthGaurdService], data: { roles: ["CLIENT", "ADMIN"] } },
  // { path: 'notification/detail/:id', component: NotificationsDetailComponent, canActivate: [AuthGaurdService], data: { roles: ["CLIENT", "ADMIN"] } },
  { path: 'destination', component: DestinationComponent, canActivate: [AuthGaurdService], data: { roles: ["CLIENT", "ADMIN"] } },
  { path: 'destination/detail/', component: DestinationDetailComponent, canActivate: [AuthGaurdService], data: { roles: ["CLIENT", "ADMIN"] } },
  { path: 'destination/detail/:id', component: DestinationDetailComponent, canActivate: [AuthGaurdService], data: { roles: ["CLIENT", "ADMIN"] } },
  { path: 'destination/detail/:destinationId/notification', component: NotificationsComponent, canActivate: [AuthGaurdService], data: { roles: ["CLIENT", "ADMIN"] } },
  { path: 'destination/detail/:destinationId/notification/detail', component: NotificationsDetailComponent, canActivate: [AuthGaurdService], data: { roles: ["CLIENT", "ADMIN"] } },
  { path: 'destination/detail/:destinationId/notification/detail/:notificationId', component: NotificationsDetailComponent, canActivate: [AuthGaurdService], data: { roles: ["CLIENT", "ADMIN"] } },
  { path: 'point', component: PointComponent, canActivate: [AuthGaurdService], data: { roles: ["CLIENT", "ADMIN"] } },
  { path: 'destination/detail/:id/point/detail/:idPoint', component: PointDetailComponent, canActivate: [AuthGaurdService], data: { roles: ["CLIENT", "ADMIN"] } },
  { path: 'destination/detail/:id/point/detail', component: PointDetailComponent, canActivate: [AuthGaurdService], data: { roles: ["CLIENT", "ADMIN"] } },
  { path: 'destination/detail/:id/point', component: PointComponent, canActivate: [AuthGaurdService], data: { roles: ["CLIENT", "ADMIN"] } },
  { path: 'destination/detail/:id/route/detail/:idRoute', component: RouteDetailComponent, canActivate: [AuthGaurdService], data: { roles: ["CLIENT", "ADMIN"] } },
  { path: 'destination/detail/:id/route/detail', component: RouteDetailComponent, canActivate: [AuthGaurdService], data: { roles: ["CLIENT", "ADMIN"] } },
  { path: 'destination/detail/:id/route', component: RouteComponent, canActivate: [AuthGaurdService], data: { roles: ["CLIENT", "ADMIN"] } },
  { path: 'logout', component: LogoutComponent },
  {
    path: 'admin', component: AdminDashboardComponent, canActivate: [AuthGaurdService], data: { roles: ["ADMIN"] },
    children: [
      { path: '', pathMatch: 'full', component: AdminClientComponent },
      { path: 'user',  component: AdminClientComponent },
      { path: 'user/detail', component: AdminClientDetailComponent },
      { path: 'user/detail/:id', component: AdminClientDetailComponent },
      { path: 'notification', component: AdminNotificationComponent },
      { path: 'notification/detail', component: NotificationsDetailComponent },
      { path: 'notification/detail/:notificationId', component: NotificationsDetailComponent },
      { path: 'point', component: AdminPointComponent },
      { path: 'point/detail', component: PointDetailComponent },
      { path: 'point/detail/:idPoint', component: PointDetailComponent },
      { path: 'route', component: AdminRouteComponent },
      { path: 'route/detail', component: RouteDetailComponent },
      { path: 'route/detail/:id/:idRoute', component: RouteDetailComponent },
      { path: 'topic', component: AdminTopicComponent },
      { path: 'topic/detail', component: AdminTopicDetailComponent },
      { path: 'topic/detail/:id', component: AdminTopicDetailComponent },
      { path: 'destination', component: AdminDestinationComponent },
      { path: 'destination/detail', component: AdminDestinationDetailComponent },
      { path: 'destination/detail/:id', component: AdminDestinationDetailComponent }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
