import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../../service/httpclient.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';


const API_RESET='api/auth/user/reset';
@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss']
})
export class RecoveryComponent implements OnInit {
  recoveryForm: FormGroup; // Holds on the form
  token: string;
  isLoading = false;
  isSubmitted = false;
  errorMessage = '';
  email
  constructor(private http: HttpClientService, private route: ActivatedRoute, private _snackBar: MatSnackBar) {
    this.recoveryForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
    });
  }


  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');    
  }

  onSubmit() {
    this.isSubmitted = true;
    let params = new HttpParams();
    params = params.append('email', this.email);
    this.http.get(API_RESET, params).subscribe(
      response => {
        console.log(response);
        this.openSnackBar(response.message)
      },
      errorMessage => {
        console.log(errorMessage);
        this.openSnackBar("El correo electronico no se encuentra en nuestra base de datos.")
      }
    );
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, "Ok");
  }

}
