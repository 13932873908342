import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '../../service/token.service';
import { HttpClientService } from 'src/app/service/httpclient.service';
import { MatSnackBar } from '@angular/material';

@Component({
    selector: 'header-component',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    RESET_URL: string = 'api/user/reset';

    @Input() destination;
    @Input() secction;
    @Input() back;
    @Input() create;
    @Input() name;
    @Input() detail;

    constructor(private snackBar: MatSnackBar,
        private http: HttpClientService,
        public tokenService: TokenService,
        private router: Router) { }

    ngOnInit() {
        console.log("BACK", this.back)
    }

    navigateBack() {
   
        console.log("VAMOS AY¡", this.back);
        if (this.back) {
            this.router.navigate([this.back]);
        }
    }

    go(path: string): void {
        this.router.navigate([path]);
    }


    reset() {
        this.http.get(this.RESET_URL).subscribe(
            response => {
                this.openSnackBar("Le hemos enviado un enlace de restablecimiento a su bandeja de entrada de correo electrónico");
            },
            error => {
                console.error(error);
            }
        );
    }

    openSnackBar(message: string) {
        this.snackBar.open(message, "Cerrar", {
            duration: 4000,
            panelClass: ['success-snackbar'],
            horizontalPosition: 'center',
            verticalPosition: 'top'
        });
    }
}