import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class FileService  {
 


    resizeImage(base64Str: string, maxWidth: number, maxHeight: number, quality: number): Promise<string> {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = base64Str;
          img.onload = () => {
            let width = img.width;
            let height = img.height;
    
            if (width > maxWidth) {
              height = Math.round((maxWidth / width) * height);
              width = maxWidth;
            }
    
            if (height > maxHeight) {
              width = Math.round((maxHeight / height) * width);
              height = maxHeight;
            }
    
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);
    
            resolve(canvas.toDataURL('image/jpeg', quality));
          };
          img.onerror = (error) => reject(error);
        });
      }
}
