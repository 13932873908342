import { Component, OnInit, ViewChild } from '@angular/core';

import { MatDialog, MatPaginator } from '@angular/material';
import { debounceTime, distinctUntilChanged} from 'rxjs/operators';

import { Subject,merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap, delay } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { HttpClientService } from '../../service/httpclient.service'
import { MatSort, Sort } from '@angular/material/sort';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { Platform } from 'src/app/models/Platform';
import { PlatformService } from 'src/app/service/Platform.service';

const API = "api/destination";
const API_POINT = "api/point/";
const API_FILE = 'api/file/'

@Component({
  selector: 'app-admin-destination',
  templateUrl: './admin-destination.component.html',
  styleUrls: ['./admin-destination.component.scss']
})
export class AdminDestinationComponent implements OnInit {
  selectedPlatforms: Set<String> = new Set<String>();

  file;
  dataSource: Array<any> = [];
  resultsLength = 100;
  displayedColumns: string[] = ['name', 'origin', 'notifications', 'platforms', 'status', 'download', 'edit', 'remove'];
  platforms!: any;
  platformOptions = Object.values(Platform);

  statusFilter = "";
  nameFilter = "";
  empty = false;
  nameSearch = '';
  private searchSubject = new Subject<void>();
  pointNames!: string[];
  constructor(private http: HttpClientService, public dialog: MatDialog,  private platformService: PlatformService) { }

  ngOnInit() {
    this.findPlatform();
    this.searchSubject
    .pipe(
      debounceTime(500),
      // distinctUntilChanged() // opcional si quieres ignorar valores repetidos
    )
    .subscribe((sort) => {
      this.find(sort);   // Aquí llamamos a la lógica real
    });
    this.find(false);
  }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  find(sort) {
    const platformsArray = Array.from(this.selectedPlatforms); 

    merge(this.paginator.page)
      .pipe(
        startWith({}),
        delay(0),
        switchMap(() => {
          let params = new HttpParams()
          .set('platforms', platformsArray.toString())
            .set('status', this.statusFilter.toString())
            .set('name', this.nameFilter.toString())
            .set('pageIndex', this.paginator.pageIndex.toString()).set('pageSize', this.paginator.pageSize.toString());
          if (sort == true) {
            params = params.set('sort', this.sort.active + "," + this.sort.direction);
          }
          if (this.nameSearch != null) {
            params = params.set('name', this.nameSearch);
          }
          return this.http.get(API + '/search/admin', params);
        }),
        map(data => {
          this.resultsLength = data.totalElements;
          return data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => {
        this.dataSource = data.content;
        this.findNamesPoint(this.dataSource.filter(des => des.originPoint != null).map(des => des.originPoint));
        if (this.dataSource.length == 0) {
          this.empty = true;
        } else {
          this.empty = false;
        }
      });
  }

  onNameSearchChange() {
    this.searchSubject.next();
  }

  onFileSelected(event, destinationId) {
    this.file = event.target.files[0]
    this.uploadFile(this.file, destinationId).subscribe(file => {
      console.log(file);
    });
  }

  uploadFile(file, destinationId) {
    let fileName = file.name;
    let format = file.name.split('.').pop();
    const formData = new FormData();
    formData.append("format", format);
    formData.append("file", file);
    formData.append("destinationId", destinationId);
    return this.http.postImg(API_FILE + 'point', formData);
  }

  announceSortChange(sortState: Sort) {
    this.find(true);
  }

  findNamesPoint(ids) {
    const params = new HttpParams({ fromObject: { ids: ids } });

    this.http.get(API_POINT + 'namesByIds', params).subscribe(result => {
      this.dataSource.filter(des => des.originPoint != null).forEach(des => {
        des.nameOriginPoint = result.find(p => p.id === des.originPoint).name;
        console.log(des);
      })
    })
  }

  remove(id) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { id: id, type: 'destination', title: 'Eliminar destino', text: '¿Quieres eliminar este destino permanentemente?', mainButton: 'Eliminar' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.id) {
        this.delete(result.id);
      }
    });
  }

  delete(id){
    this.http.deleteById(API+"/"+id).subscribe(response=>{ this.find(false)});
  }

  downloadPoint(destinationId) {
    console.log(destinationId);
    const url = `${API}/${destinationId}/points-xls`;

    this.http.download(url).subscribe(result => {
      // const blob = new Blob([result], { type: 'application/octet-stream' });
      // const urlfile= window.URL.createObjectURL(blob);
      // window.open(urlfile);
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(result);
      a.href = objectUrl;
      a.download = `points_${destinationId}.xlsx`;
      a.click();
      URL.revokeObjectURL(objectUrl);
    }, error => {
      console.error('Error descargando el archivo', error);
    });
  }

  isPlatformSelected(platform: Platform): boolean {
    return this.selectedPlatforms.has(platform.name);
  }

  onCheckboxChange(platform: Platform, isChecked: boolean) {
    if (isChecked) {
      this.selectedPlatforms.add(platform.name);
    } else {
      this.selectedPlatforms.delete(platform.name);
    }
  }

  findPlatform(){
    this.platformService.platforms$.subscribe({
      next: (platforms) => {
        this.platformOptions = platforms;
        this.platforms = this.platformOptions;
        console.log("Platforms!" + platforms);
      },
      error: (error) => {
        console.error('Failed to load platforms', error);
      }
    });
  }
}
