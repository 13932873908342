import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClientService } from '../../service/httpclient.service';
import { MatDialog, MatPaginator } from '@angular/material';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap, delay } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { MatSort, Sort } from '@angular/material/sort';
import { TokenService } from 'src/app/service/token.service';
import { ActivatedRoute } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

const API_DESTINATION = 'api/destination/'

const API_ROUTE = 'api/route/';
const API_ADMIN_ROUTE = 'api/admin/route/';
@Component({
  selector: 'app-admin-route',
  templateUrl: './admin-route.component.html',
  styleUrls: ['./admin-route.component.scss']
})
export class AdminRouteComponent implements OnInit {
  searchControl = new FormControl();

  dataSource: Array<any> = [];
  resultsLength = 100;
  displayedColumns: string[] = ['name', 'popularity','distance', 'duration', 'edit', 'delete'];
  routes;
  destinationId;
  destination;
  destinations = [];
  destinationFilter = [];
  empty = false;
  nameSearch = '';

  constructor(private http: HttpClientService, public tokenService: TokenService,
    private activeRoute: ActivatedRoute, public dialog: MatDialog) { }

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.searchControl.valueChanges.pipe(
      debounceTime(500),  // Espera 300ms
      distinctUntilChanged()  // Solo continúa si el valor ha cambiado
    ).subscribe(searchTerm => {
      this.nameSearch = searchTerm;
      this.find(false);

    });
    this.find(false);
    this.findDestination();
  }

  findDestination() {
    this.http.get(API_DESTINATION).subscribe(result => {
      this.destinations = result;
    })
  }

  find(sort) {

    merge(this.paginator.page)
      .pipe(
        startWith({}),
        delay(0),
        switchMap(() => {
          let params = new HttpParams().set('pageIndex', this.paginator.pageIndex.toString())
            .set('destinations', this.destinationFilter.toString())
            .set('pageSize', this.paginator.pageSize.toString());
          if (sort == true) {
            params = params.set('sort', this.sort.active + "," + this.sort.direction);
          }
          if (this.nameSearch != null) {
            params = params.set('name', this.nameSearch);
          }
          return this.http.get(API_ADMIN_ROUTE + 'search', params);
        }),
        map(data => {
          this.resultsLength = data.totalElements;
          return data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => {
        this.dataSource = data.content;
        if (this.dataSource.length == 0) {
          this.empty = true;
        } else {
          this.empty = false;
        }
      });
  }

  remove(id) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { id: id, type: 'route', title: 'Eliminar ruta', text: '¿Quieres eliminar este ruta permanentemente?', mainButton: 'Eliminar' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.id) {
        this.find(false);
      }
    });
  }

  announceSortChange(sortState: Sort) {
    this.find(true);
  }

}
