import { Component, OnInit } from '@angular/core';
import { Notification, NotificationType } from 'src/app/models/Notification';
import { TokenService } from 'src/app/service/token.service';
import { HttpClientService } from 'src/app/service/httpclient.service';
import { Router, ActivatedRoute } from "@angular/router";
import { PlatformService } from 'src/app/service/Platform.service';
import { Platform } from 'src/app/models/Platform';
const API_NOTIFICATION = 'api/notification/'
const API_DESTINATION = "api/destination/"

@Component({
  selector: 'app-notifications-detail',
  templateUrl: './notifications-detail.component.html',
  styleUrls: ['./notifications-detail.component.scss']
})
export class NotificationsDetailComponent implements OnInit {

  platforms: Platform[] = [];
  platformOptions: any[] = [];
  selectedPlatforms: Set<String> = new Set<String>();
  notificationId;
  destinationId;
  destination;
  date: any;
  time: any;
  userId!: string;
  notification: Notification = {
    id: null,
    createDate: null,
    senderDate: null,
    sender: null,
    receiver: null,
    alert: false,
    validityPeriod: "H_24",
    notificationMethod: null, // Asumiendo que tienes un enum para esto
    status: null, // Asumiendo que tienes un enum para esto
    title: '',
    message: '',
    scheduled: false,
    notificationType: null,
    platforms: null,
    expirationDate: null,
    destinationId: '' // Valor inicial predeterminado
  };
  constructor(private platformService: PlatformService,
    public tokenService: TokenService,
    private http: HttpClientService, private activeRoute: ActivatedRoute, public router: Router) { }

  ngOnInit() {
    this.platformService.platforms$.subscribe({
      next: (platforms) => {
        this.platformOptions = platforms;
        this.platforms = platforms;
        console.log('Platforms loaded:', platforms);
      },
      error: (error) => {
        console.error('Failed to load platforms:', error);
      }
    });

    this.userId = this.tokenService.getUserId();
    if (!this.tokenService.isAdmin()) {
      this.notification.notificationType = NotificationType.CLIENT_AD;
    }
    this.activeRoute.paramMap.subscribe(params => {
      if (params.get('notificationId') != null) {
        this.notificationId = params.get('notificationId');
        this.findNotification();
      }
      if (params.get('destinationId') != null) {
        this.destinationId = params.get('destinationId');
        this.findDestination();
      }
    });
  }

  findNotification() {
    this.http.get(API_NOTIFICATION + this.notificationId).subscribe(response => {
      this.selectedPlatforms = new Set<String>(response.platforms.map(p => p.name));

      this.notification = response;
    })
  }

  saveNotification() {

    if (this.date && this.time) {
      const combinedDateTime = `${this.date}T${this.time}`;
      const fullDateTime = new Date(combinedDateTime);
      const timezoneOffset = fullDateTime.getTimezoneOffset() * 60000; // offset en milisegundos
      const localDateTime = new Date(fullDateTime.getTime() - timezoneOffset);
      this.notification.senderDate = localDateTime.toISOString().slice(0, -1); // Remover el 'Z'
    }
    console.log(this.notification.senderDate);

    this.notification.destinationId = this.destinationId;
    this.http.post(API_NOTIFICATION, this.notification).subscribe(response => {
      this.goBack();
    });

  }
  findDestination(){
    this.http.get(API_DESTINATION+this.destinationId).subscribe(response=>{
      this.destination=response;
      const destinationPlatformIds = this.destination.platformNotificationStatus
      ? this.destination.platformNotificationStatus
          .filter(status => status.enabledNotifications)
          .map(status => status.platform.name)
      : [];
    
      
    // Filtramos las plataformas para excluir las que ya están asociadas al destino
    this.platformOptions = this.platformOptions.filter(platform => 
      destinationPlatformIds.includes(platform.name)
    );

    });
  }


  goBack() {
    if (this.tokenService.isAdmin()) {
      this.router.navigate(['/admin/notification']);
    } else {
      this.router.navigate(['/destination/detail/' + this.destinationId + '/notification']);
    }

  }

  onCheckboxChange(platform: Platform, checked: boolean) {
    if (checked) {
      this.selectedPlatforms.add(platform.name);
    } else {
      this.selectedPlatforms.delete(platform.name);
    }
    this.notification.platforms = this.platforms.filter(p => this.selectedPlatforms.has(p.name)).map(p => p.name);

  }

  isChecked(platform) {
    console.log(platform, this.selectedPlatforms.has(platform))
    return this.selectedPlatforms.has(platform.name);
  }

  isSaveDisabled(): boolean {
    if (!this.notification) {
      return true; // Bloqueado si no hay notificación
    }
    if (!this.notification.platforms || this.notification.platforms.length==0 ) {
      return true; // Bloqueado si no hay notificación
    }

    // Validación de campos obligatorios
    if (!this.notification.title || !this.notification.message || !this.notification.validityPeriod) {
      return true;
    }

    // Si el periodo de validez es "PROGRAM", la fecha de expiración es obligatoria
    if (this.notification.validityPeriod === "PROGRAM" && !this.notification.expirationDate) {
      return true;
    }

    // Si la notificación es programada, debe tener fecha y hora de envío
    if (this.notification.scheduled && (!this.date || !this.time)) {
      return true;
    }

    return false; // Si pasa todas las validaciones, el botón se activa
  }

}
