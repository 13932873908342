import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { NgZone } from '@angular/core';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGaurdService implements CanActivate {

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private tokenService: TokenService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // Verifica si el token está guardado en el localStorage
    const token = localStorage.getItem('authToken');
     // Asegúrate de que el nombre de la clave coincide con tu implementación

    if (!token) {
      this.ngZone.run(() => this.router.navigate(['login']));
      return false;
    }

    // Si el token está en el localStorage, verifica los roles
    if (!route.data.roles) {
      return true;
    }

    const userHasRole = this.tokenService.getAuthorities().some(auth => route.data.roles.includes(auth));
    if (userHasRole) {
      return true;
    }

    this.ngZone.run(() => this.router.navigate(['']));
    return false;
  }
}
