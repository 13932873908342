import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClientService } from '../../service/httpclient.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

const API_RESET = 'api/auth/user/savePassword'
@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {
  resetForm: FormGroup; // Holds on the form
  token: string;
  id;
  isLoading = false;
  isSubmitted = false;
  errorMessage = '';

  constructor(public router: Router, private http: HttpClientService, private _snackBar: MatSnackBar, private route: ActivatedRoute) {
    this.resetForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
    });
   }

  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');
    this.id = this.route.snapshot.paramMap.get('id');
    this.resetForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required, Validators.minLength(6)]),
      repeat_password: new FormControl(null, [Validators.required, Validators.minLength(6)])
    }, [
      this.passwordValidator
    ]);
  }

  onSubmit() {
    this.isSubmitted = true;
    this.http.post(API_RESET, {
      id: this.id,
      resetToken: this.token,
      email: this.resetForm.get('email').value,
      password: this.resetForm.get('password').value
    }).subscribe(
      response => {
        this.openSnackBar("Operación realizada con éxito", "Cerrar");
        console.log(response);
      },
      errorMessage => {
        console.log(errorMessage);
      }
    );
  }

  passwordValidator(form: FormGroup) {
    const passwordValue = form.get('password').value;
    const passwordRepeatValue = form.get('repeat_password').value;

    if (passwordValue === passwordRepeatValue) {
      return null
    } else {
      form.get('repeat_password').setErrors({ repeat_password: true })
      return {
        passwordvalidator: 'The passwords entered are not the same'
      }
    }
  }

  openSnackBar(message: string, action: string) {
    const snackBarRef = this._snackBar.open(message, action, {
      duration: 3000
    });
    snackBarRef.afterDismissed().subscribe(info => {
      this.go();
    });

  }

  go() {
    this.router.navigate(['/login']);
  }

}
