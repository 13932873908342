import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-route-detail',
  templateUrl: './admin-route-detail.component.html',
  styleUrls: ['./admin-route-detail.component.scss']
})
export class AdminRouteDetailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
