import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../../../service/httpclient.service';
import { Router, ActivatedRoute } from "@angular/router";
import { User } from 'src/app/models/User';

const API_ROLE = 'api/role/';
const API_USER = 'api/user/';
const API_DESTINATION = 'api/destination/';
const API_COMMENTS = 'api/comments/user/';
const API_FAVS = 'api/favorite/user/';
const API_ROUTE = 'api/route/user/';


@Component({
  selector: 'app-admin-client-detail',
  templateUrl: './admin-client-detail.component.html',
  styleUrls: ['./admin-client-detail.component.scss']
})
export class AdminClientDetailComponent implements OnInit {

  userId: string;
  user: User = new User(); // Inicializamos user con un objeto User vacío
  roles = [];
  destinations: any[] = [];


  constructor(private router: Router,
    private activeRoute: ActivatedRoute,
    private http: HttpClientService
  ) { }

  ngOnInit() {
    this.findAllRoles();
    this.activeRoute.paramMap.subscribe(params => {
      if (params.get('id') != null) {
        this.userId = params.get('id');
        this.findUser();
      }
    });
  }

  findUser() {
    this.http.get(API_USER + this.userId).subscribe(
      response => {
        this.user = response;
        this.findFavs();
        this.findRoutes();
        this.findComments()
        if (Array.from(this.user.role).some(r => r.name === 'CLIENT')) {
          this.findDestinations();
        }
      }
    );
  }
  favsUser;
  findFavs() {
    this.http.get(API_FAVS + this.user.id).subscribe(
      response => {
        this.favsUser = response;
      }
    );
  }
  rutasUser;
  findRoutes() {
    this.http.get(API_ROUTE + this.user.id).subscribe(
      response => {
        this.rutasUser = response;
      }
    );
  }
  commentsUser;
  findComments() {
    this.http.get(API_COMMENTS + this.user.id).subscribe(
      response => {
        this.commentsUser = response;
      }
    );
  }

  findAllRoles() {
    this.http.get(API_ROLE).subscribe(
      response => {
        this.roles = Array.from(response);
      }
    );
  }

  findDestinations() {
    this.http.get(API_DESTINATION + 'user/' + this.user.id).subscribe(
      response => {
        this.destinations = response;
      }
    );
  }

  save() {
    if (this.userId != null) {
      this.http.put(API_USER, this.user).subscribe(
        response => {
          this.goBack();
        }
      );
    } else {
      this.http.post(API_USER, this.user).subscribe(
        response => {
          this.goBack();
        }
      );
    }
  }

  goBack() {
    this.router.navigate(['/admin/user/']);
  }

  deleteDestination(destination) {
    this.http.deleteAll(`${API_DESTINATION}${destination.id}/users/${this.userId}`).subscribe(result => {
      this.findUser();
    })
  }

}
