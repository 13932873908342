import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClientService } from 'src/app/service/httpclient.service';

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm-component.html',
    styleUrls: ['./confirm-component.scss']
})

export class ConfirmComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<ConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    ngOnInit() {
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    removeFav() {
        return of(this.data);
    }


}
