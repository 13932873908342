import { Injectable } from '@angular/core';
import { jwtDecode } from "jwt-decode";
const USER_ID = 'userId';
const TOKEN_KEY = 'AuthToken';
const USERNAME_KEY = 'AuthUserName';
const AUTHORITIES_KEY = 'AutAuthorities';
const DESTINATION = 'destination';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  roles: Array<string> = [];

  constructor() { }

  public setToken(token: string): void {
    console.log("Guardando token", token);
    localStorage.setItem('authToken', token);
    window.sessionStorage.setItem('authToken', token);
    let user=this.getTokenDecoded(token);
    window.sessionStorage.removeItem(USER_ID);
    window.sessionStorage.setItem(USER_ID, user.idUser);
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public setDestination(dest): void {
    window.sessionStorage.removeItem(DESTINATION);
    window.sessionStorage.setItem(DESTINATION, JSON.stringify(dest));
  }

  public getDestination() {
    const item = window.sessionStorage.getItem(DESTINATION);
    if (item !== null) {
      return item;
    } else {
      // Manejar el caso en que el valor es null, por ejemplo, devolver un valor predeterminado
      return ''; // Reemplaza esto con lo que sea apropiado para tu caso
    }
  }

  public getToken(): string {
    const item = window.sessionStorage.getItem(TOKEN_KEY);
    if (item !== null) {
      return item;
    } else {
      // Manejar el caso en que el valor es null, por ejemplo, devolver un valor predeterminado
      return ''; // Reemplaza esto con lo que sea apropiado para tu caso
    }
  }

  public setUserName(userName: string): void {
    window.sessionStorage.removeItem(USERNAME_KEY);
    window.sessionStorage.setItem(USERNAME_KEY, userName);
  }

  public getUserName(): string {
    const item = window.sessionStorage.getItem(USERNAME_KEY);
    if (item !== null) {
      return item;
    } else {
      // Manejar el caso en que el valor es null, por ejemplo, devolver un valor predeterminado
      return ''; // Reemplaza esto con lo que sea apropiado para tu caso
    }
  }

  public getUserId(): string {
    const item = window.sessionStorage.getItem(USER_ID);
    if (item !== null) {
      return item;
    } else {
      // Manejar el caso en que el valor es null, por ejemplo, devolver un valor predeterminado
      return ''; // Reemplaza esto con lo que sea apropiado para tu caso
    }
  }

  public setAuthorities(authorities: string[]): void {
    window.sessionStorage.removeItem(AUTHORITIES_KEY);
    window.sessionStorage.setItem(AUTHORITIES_KEY, JSON.stringify(authorities));
  }

  public getAuthorities(): string[] {
    this.roles = [];
    const item = window.sessionStorage.getItem(AUTHORITIES_KEY);
    if (item !== null) {
      JSON.parse(item).forEach(authority => {
        this.roles.push(authority.authority);
      });
    }
    return this.roles;
  }

  public isAdmin(): boolean {
    this.roles = this.getAuthorities();

    return this.roles.includes('ADMIN');
  }

  isUserLoggedIn() {
    let user = window.sessionStorage.getItem(USERNAME_KEY)
    return !(user === null)
  }

  public logOut(): void {
    localStorage.removeItem('authToken');
    window.sessionStorage.clear();
  }

  public getTokenDecoded(token): any {
    if (token) {
      try {
        return jwtDecode(token);
      } catch (error) {
        console.error("Error al decodificar el token", error);
        return null;
      }
    }
    return null;
  }
}
