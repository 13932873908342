import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HttpClientService } from '../../service/httpclient.service'
import { Router, ActivatedRoute } from "@angular/router";
import { TokenService } from '../../service/token.service';
import { HttpParams } from '@angular/common/http';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

const API_ROUTE = "api/route/"
@Component({
  selector: 'app-route',
  templateUrl: './route.component.html',
  styleUrls: ['./route.component.scss']
})
export class RouteComponent implements OnInit {
  destinationId;
  destination;
  routes;
  nameSearch = '';
  searchControl = new FormControl();

  constructor(private http: HttpClientService,
    public tokenService: TokenService,
    private activeRoute: ActivatedRoute,
    public router: Router, public dialog: MatDialog) { }

  ngOnInit() {

    this.searchControl.valueChanges.pipe(
      debounceTime(500),  // Espera 300ms
      distinctUntilChanged()  // Solo continúa si el valor ha cambiado
    ).subscribe(searchTerm => {
      this.findRoutes(searchTerm);

    });
    this.destination = this.tokenService.getDestination();
    this.activeRoute.paramMap.subscribe(params => {
      if (params.get('id') != null) {
        this.destinationId = params.get('id');
        this.findRoutes('');
      }
    });
  }

  findRoutes(searchTerm) {
    let params = new HttpParams()
      .set('name', searchTerm)
    this.http.get(API_ROUTE + "destination/" + this.destinationId, params).subscribe(result => {
      this.routes = result;
      console.log("rutas", this.routes)
    })
  }

  delete(id) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { id: id, type: 'route', title: 'Eliminar ruta', text: '¿Quieres eliminar esta ruta permanentemente?', mainButton: 'Eliminar' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.id) {
        this.findRoutes('');
      }
    });
  }

}
