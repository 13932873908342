import { Component, OnInit } from '@angular/core';
import { Notification, NotificationType } from 'src/app/models/Notification';
import { TokenService } from 'src/app/service/token.service';
import { HttpClientService } from 'src/app/service/httpclient.service';
import { Router, ActivatedRoute } from "@angular/router";

const API_NOTIFICATION = 'api/notification/'

@Component({
  selector: 'app-notifications-detail',
  templateUrl: './notifications-detail.component.html',
  styleUrls: ['./notifications-detail.component.scss']
})
export class NotificationsDetailComponent implements OnInit {
  notificationId;
  destinationId;
  date: any;
  time: any;
  userId!: string;
  notification: Notification = {
    id: null,
    createDate: null,
    senderDate: null,
    sender: null,
    receiver: null,
    alert: false,
    validityPeriod: null,
    notificationMethod: null, // Asumiendo que tienes un enum para esto
    status: null, // Asumiendo que tienes un enum para esto
    title: '',
    message: '',
    scheduled: false,
    notificationType: null,
    destinationId: '' // Valor inicial predeterminado
  };
  constructor(public tokenService: TokenService, private http: HttpClientService, private activeRoute: ActivatedRoute, public router: Router) { }

  ngOnInit() {
    this.userId = this.tokenService.getUserId();
    if (!this.tokenService.isAdmin()) {
      this.notification.notificationType = NotificationType.CLIENT_AD;
    }
    this.activeRoute.paramMap.subscribe(params => {
      if (params.get('notificationId') != null) {
        this.notificationId = params.get('notificationId');
        this.findNotification();
      }
      if (params.get('destinationId') != null) {
        this.destinationId = params.get('destinationId');
      }
    });
  }

  findNotification() {
    this.http.get(API_NOTIFICATION + this.notificationId).subscribe(result => {
      this.notification = result;
    })
  }

  saveNotification() {

    if (this.date && this.time) {
      const combinedDateTime = `${this.date}T${this.time}`;
      const fullDateTime = new Date(combinedDateTime);
      const timezoneOffset = fullDateTime.getTimezoneOffset() * 60000; // offset en milisegundos
      const localDateTime = new Date(fullDateTime.getTime() - timezoneOffset);
      this.notification.senderDate = localDateTime.toISOString().slice(0, -1); // Remover el 'Z'
    }
    console.log(this.notification.senderDate);

    this.notification.destinationId = this.destinationId;
    this.http.post(API_NOTIFICATION, this.notification).subscribe(response => {
      this.goBack();
    });

  }



  goBack() {
    if (this.tokenService.isAdmin()) {
      this.router.navigate(['/admin/notification']);
    } else {
      this.router.navigate(['/destination/detail/' + this.destinationId + '/notification']);
    }

  }

}
