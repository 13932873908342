import { MicrositeRoute } from "./Microsite.model";
export class Route {
    id?: string;
    dateHoursRange?: any[];
    destinations?: string[];
    userId?: string;
    name?: string;
    popularity?: number;
    price?: number;
    numberOfComments?: number;
    totalDistanceInMeters?: number;
    totalDurationInMinutes?: number;
    numberOfDays?: number;
    legs?: any[];
    topics?: string[];
    hasEvents?: boolean;
    visible?: boolean;
    preSave?: boolean;
    isMicrosite?: boolean;
    priceCategory?: any;
    source?: any;
    isPreconfigured?: boolean;
    verified?: boolean;
    lastChanges?: string;
    avgFeedback?: any;
    micrositeRoute?: MicrositeRoute;
  
    constructor(
      id?: string,
      dateHoursRange?: any[],
      destinations?: string[],
      userId?: string,
      name?: string,
      popularity?: number,
      price?: number,
      numberOfComments?: number,
      totalDistanceInMeters?: number,
      totalDurationInMinutes?: number,
      numberOfDays?: number,
      legs?: any[],
      topics?: string[],
      hasEvents?: boolean,
      visible?: boolean,
      preSave?: boolean,
      isMicrosite?: boolean,
      priceCategory?: any,
      source?: any,
      isPreconfigured?: boolean,
      verified?: boolean,
      lastChanges?: string,
      avgFeedback?: any,
      micrositeRoute?: MicrositeRoute
    ) {
      this.id = id;
      this.dateHoursRange = dateHoursRange || [];
      this.destinations = destinations || [];
      this.userId = userId || '';
      this.name = name || '';
      this.popularity = popularity || 0;
      this.price = price || 0;
      this.numberOfComments = numberOfComments || 0;
      this.totalDistanceInMeters = totalDistanceInMeters || 0;
      this.totalDurationInMinutes = totalDurationInMinutes || 0;
      this.numberOfDays = numberOfDays || 0;
      this.legs = legs || [];
      this.topics = topics || [];
      this.hasEvents = hasEvents || false;
      this.visible = visible || false;
      this.preSave = preSave || false;
      this.isMicrosite = isMicrosite || false;
      this.priceCategory = priceCategory || undefined;
      this.source = source || undefined;
      this.isPreconfigured = isPreconfigured || false;
      this.verified = verified || false;
      this.lastChanges = lastChanges || '';
      this.avgFeedback = avgFeedback || undefined;
      this.micrositeRoute = micrositeRoute || undefined;
    }
  }
  

  