import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
const header = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {

  constructor(private httpClient: HttpClient) { }

  public get(url: string, params?: HttpParams):Observable<any[any]>  {
    return this.httpClient.get<any[any]>(API_URL + url, { params, headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
  }

  public getBase64(url: string,):Observable<any[any]>  {
    return this.httpClient.get<any[any]>(API_URL + url);
  }


  public delete(url: string, object: any) {
    return this.httpClient.delete<any>(API_URL + url + object.id, header);
  }

  public deleteAllIds(url: string, params?: HttpParams) {
    return this.httpClient.delete<any>(API_URL + url ,{ params, headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
  }

  public deleteAll(url: string) {
    return this.httpClient.delete<any>(API_URL + url , header);
  }

  public deleteById(url: string) {
    return this.httpClient.delete<any>(API_URL + url , header);
  }

  public remove(url: string) {
    return this.httpClient.delete<any>(API_URL + url, header);
  }
  public post(url: string, object: any) {
    return this.httpClient.post<any>(API_URL + url, object, header);
  }

  public postImg(url: string, object: any) {
    return this.httpClient.post<any>(API_URL + url, object);
  }


  public put(url: string, object: any) {
    return this.httpClient.put<any>(API_URL + url, object);
  }
  public patch(url: string,  params?: HttpParams) {
    return this.httpClient.patch<any>(API_URL + url, {params});
  }

  public download(url: string): Observable<Blob> {
    return this.httpClient.get(`${API_URL}${url}`, {
      responseType: 'blob'
    });
  }
}