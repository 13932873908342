import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../../../service/httpclient.service';
import { Router, ActivatedRoute } from "@angular/router";

const API_TOPIC = 'api/topic/';

@Component({
  selector: 'app-admin-topic-detail',
  templateUrl: './admin-topic-detail.component.html',
  styleUrls: ['./admin-topic-detail.component.scss']
})
export class AdminTopicDetailComponent implements OnInit {
  topic: any = { names: [{ lang: "en", s: "" }, { lang: "es", s: "" }] };
  topics: any[];
  topicId;

  constructor(private router: Router,
    private activeRoute: ActivatedRoute,
    private httpClientService: HttpClientService
  ) { }

   ngOnInit() {
    this.findAllTopics();
    this.activeRoute.paramMap.subscribe(params => {
      if (params.get('id') != null) {
        this.topicId = params.get('id');
        this.findTopic();
      }
    });
  }

  findAllTopics(){
    this.httpClientService.get(API_TOPIC).subscribe(
      response => {     
        this.topics = response.filter(topic=>topic.parentId == null);       
      }
    );
  }

  findTopic(){
    this.httpClientService.get(API_TOPIC+this.topicId).subscribe(
      response => {           
        this.topic = response;
      }
    );
  }

  save(){
    if(this.topic.priceRange!=null){
      this.topic.priceRange=this.topic.priceRange.split(",");
    }
    this.httpClientService.post(API_TOPIC, this.topic).subscribe(
      response => {     
        this.goBack();
      }
    );
  }

  goBack() {
    this.router.navigate(['/admin/topic/']);
  }

}
