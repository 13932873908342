import { Component,Input,EventEmitter,Output, OnInit, SimpleChanges,OnChanges } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { HttpClientService } from 'src/app/service/httpclient.service';
import { Router } from '@angular/router';

const API_FILE: string = 'api/file/';
const API_ROUTE = "api/route/"
@Component({
  selector: 'app-list-routes',
  templateUrl: './list-routes.component.html',
  styleUrls: ['./list-routes.component.scss']
})
export class ListRoutesComponent implements OnInit, OnChanges {
  @Input() routes!:any;
  @Input() mines!:any;
  @Output() loaded = new EventEmitter<number>();
  userId!:string;

  public routeImgs=[
    {src:'assets/img/icon-path-0.png'},
    {src:'assets/img/icon-path-1.png'},
    {src:'assets/img/icon-path-2.png'},
    {src:'assets/img/icon-path-3.png'},
    {src:'assets/img/icon-path-4.png'},
    {src:'assets/img/icon-path-5.png'},
    {src:'assets/img/icon-path-6.png'},
    {src:'assets/img/icon-path-7.png'},
    {src:'assets/img/icon-path-8.png'},
    {src:'assets/img/icon-path-9.png'}
  ];


  constructor(private http: HttpClientService, private _snackBar: MatSnackBar,
    public dialog: MatDialog, private router: Router) { }

  ngOnInit(){
    console.log(this.routes)

  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['routes'] && this.routes) {
      console.log(this.routes)
    }
  }

  go(path: string) {
    this.router.navigate([path]);
  }
}
