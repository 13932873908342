import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClientService } from 'src/app/service/httpclient.service';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})

export class ConfirmDialogComponent implements OnInit {
  @Output() updateRoutes = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private _snackBar: MatSnackBar, private http: HttpClientService,
  ) {}

  ngOnInit() {
    // console.log(this.data);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  mainAction(){
    if(this.data.mainButton == 'Eliminar'){
      this.removeFav(this.data.id);
    }
  }

  removeFav(id) {

     this.http.deleteById('api/'+ this.data.type +'/'+ id).pipe(
       catchError(error => {
         this.openSnackBar("Error al eliminar la ruta");
         return of(null); 
       })
     ).subscribe(response => {
       this.openSnackBar("Ruta eliminada")

     });
  }

  openSnackBar(message: string, action?: string) {
    this._snackBar.open(message ,action, { duration: 3000});
    this.closeDialog();
  }

}
