import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { HttpClientService } from 'src/app/service/httpclient.service';
const API = 'api/admin/audit-log/';
interface AuditLog {
  id: string;
  entityType: string;
  entityId: string;
  operation: string;
  timestamp: string;
  performedBy: string;
  oldValue?: any;
  newValue?: any;
}

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss']
})
export class AuditLogComponent implements OnInit, OnChanges  {

  auditLogs: AuditLog[] = [];
  displayedColumns: string[] = [
    'entityType',
    'operation',
    'timestamp',
    'performedBy'
  ];

  // Para este ejemplo usamos un entityId fijo; en la práctica podrías recibirlo por parámetro o desde otro componente.
  @Input() entityId: string;

  constructor(private http: HttpClientService) { }

  ngOnInit(): void {
    if (this.entityId) {
      this.getAuditLogs();
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    // Si cambia el valor del entityId (por ejemplo, al pasar un nuevo valor desde el padre)
    if (changes.entityId && !changes.entityId.firstChange) {
      this.getAuditLogs();
    }
  }

  getAuditLogs(): void {
    this.http.get(`${API}${this.entityId}`)
      .subscribe(data => {
        this.auditLogs = data;
      }, error => {
        console.error('Error al obtener los logs de auditoría', error);
      });
  }
}
