import { Platform } from "./Platform";


export class User {
    id: string;
    email: string;
    name: string;
    lastName: string;
    password: string;
    facebookId: string;
    googleId: string;
    appleId: string;
    imageURL: string;
    termsAccepted: boolean;
    active: boolean;
    emailVerified: boolean;
    acceptanceTime: number;
    createdDate: Date;
    tokenFCM: string;
    role: Array<Role>;
    userTokenApp: Set<User.UserTokenApp>;

    constructor(
        id: string = '',
        email: string = '',
        name: string = '',
        lastName: string = '',
        password: string = '',
        facebookId: string = '',
        googleId: string = '',
        appleId: string = '',
        imageURL: string = '',
        termsAccepted: boolean = false,
        active: boolean = false,
        emailVerified: boolean = false,
        acceptanceTime: number = 0,
        createdDate: Date = new Date(),
        tokenFCM: string = '',
        role: Array<Role> = new Array(),
        userTokenApp: Set<User.UserTokenApp> = new Set()
    ) {
        this.id = id;
        this.email = email;
        this.name = name;
        this.lastName = lastName;
        this.password = password;
        this.facebookId = facebookId;
        this.googleId = googleId;
        this.appleId = appleId;
        this.imageURL = imageURL;
        this.termsAccepted = termsAccepted;
        this.active = active;
        this.emailVerified = emailVerified;
        this.acceptanceTime = acceptanceTime;
        this.createdDate = createdDate;
        this.tokenFCM = tokenFCM;
        this.role = role;
        this.userTokenApp = userTokenApp;
    }
}

export namespace User {
    export class UserTokenApp {
        platform: Platform;
        tokenFCM: string;

        constructor(platform: Platform, tokenFCM: string) {
            this.platform = platform;
            this.tokenFCM = tokenFCM;
        }

        equals(other: UserTokenApp): boolean {
            if (this === other) return true;
            if (!other) return false;
            return this.platform === other.platform;
        }

      
    }
}
export enum RoleName {
    ADMIN = 'ADMIN',
    CLIENT = 'CLIENT',
    USER = 'USER',
    GUEST = 'GUEST',
    USER_AKARLANDA = 'USER_AKARLANDA'
}
export class Role {
    id: string;
    name: RoleName;

    constructor(id: string = '', name: RoleName) {
        this.id = id;
        this.name = name;
    }
}
