import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClientService } from '../../service/httpclient.service';
import { MatDialog, MatPaginator } from '@angular/material';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap, delay } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { MatSort, Sort } from '@angular/material/sort';
import { TokenService } from 'src/app/service/token.service';
import { ActivatedRoute } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { PlatformService } from 'src/app/service/Platform.service';
import { Platform } from 'src/app/models/Platform';
import { TopicService } from 'src/app/service/topic.service';
const API_DESTINATION = 'api/destination/'

const API_ROUTE = 'api/route/';
const API_ADMIN_ROUTE = 'api/admin/route/';
@Component({
  selector: 'app-admin-route',
  templateUrl: './admin-route.component.html',
  styleUrls: ['./admin-route.component.scss']
})
export class AdminRouteComponent implements OnInit {
  platformGroups;
  topics;
  localTopics;
  searchControl = new FormControl();
  platforms!: any;
  selectedPlatforms: Set<String> = new Set<String>();
  platformOptions = Object.values(Platform);
  dataSource: Array<any> = [];
  resultsLength = 100;
  displayedColumns: string[] = ['name', 'verified','isPreconfigured','popularity','distance', 'duration', 'edit', 'delete'];
  routes;
  destinationId;
  destination;
  destinations = [];
  destinationFilter = [];
  empty = false;
  nameSearch = '';
  topicsFilter = [];
  filters={verified:null, preconfigured:null, events:null, enabled:null}

  constructor(private http: HttpClientService, public tokenService: TokenService,
    private platformService:PlatformService, private topicService:TopicService,
    private activeRoute: ActivatedRoute, public dialog: MatDialog) { }

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.findTopics();
    this.findPlatform();
    this.searchControl.valueChanges.pipe(
      debounceTime(500),  // Espera 300ms
      distinctUntilChanged()  // Solo continúa si el valor ha cambiado
    ).subscribe(searchTerm => {
      this.nameSearch = searchTerm;
      this.find(false);

    });
    this.find(false);
    this.findDestination();
  }

  findDestination() {
    this.http.get(API_DESTINATION).subscribe(result => {
      this.destinations = result;
    })
  }

  findTopics() {
    this.topicService.getAllTopics().subscribe(result => {
      let allTopics = result;
      this.localTopics = result.filter(t => t.local === true);
      console.log("LOCALTOPICS:", this.localTopics);
   
      const nonLocalTopics = allTopics.filter(t => !t.local === true);
      this.platformGroups = this.topicService.groupByPlatformWithHierarchy(nonLocalTopics);
      let topics = result;
      let parents = topics.filter(topic => topic.parentId == null && !topic.local)
        .map(topic => {
          return { parent: topic, value: false, kids: [] };
        });
      parents.forEach(topic => {
        topic.kids = result.filter(topic => topic.local == null || topic.local == false)
          .filter(topic => !topic.local)
          .filter(topicChill => topicChill.parentId == topic.parent.id)
          .map(topic => { return { topic: topic, value: false } });
      });

      this.topics = parents;
    })
  }

  find(sort) {
    const filtersJson = JSON.stringify(this.filters);

    merge(this.paginator.page)
      .pipe(
        startWith({}),
        delay(0),
        switchMap(() => {
          let params = new HttpParams().set('pageIndex', this.paginator.pageIndex.toString())
            .set('filters', filtersJson)
            .set('destinations', this.destinationFilter.toString())
            .set('topics', this.topicsFilter.toString())
            .set('pageSize', this.paginator.pageSize.toString());
          if (sort == true) {
            params = params.set('sort', this.sort.active + "," + this.sort.direction);
          }
          if (this.nameSearch != null) {
            params = params.set('name', this.nameSearch);
          }
          return this.http.get(API_ADMIN_ROUTE + 'search', params);
        }),
        map(data => {
          this.resultsLength = data.totalElements;
          return data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => {
        this.dataSource = data.content;
        if (this.dataSource.length == 0) {
          this.empty = true;
        } else {
          this.empty = false;
        }
      });
  }

  remove(id) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { id: id, type: 'route', title: 'Eliminar ruta', text: '¿Quieres eliminar este ruta permanentemente?', mainButton: 'Eliminar' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.id) {
        this.find(false);
      }
    });
  }

  announceSortChange(sortState: Sort) {
    this.find(true);
  }


  topicsSelected(event: any, topic: any) {
    if (event.checked) {
      if (this.topicsFilter == null) {
        this.topicsFilter = [];
      }
      this.topicsFilter.push(topic);
    } else {
      this.topicsFilter = this.topicsFilter.filter(t => t != topic);
    }
  }

  findPlatform(){
    this.platformService.platforms$.subscribe({
      next: (platforms) => {
        this.platformOptions = platforms;
        this.platforms = this.platformOptions;
        console.log("Platforms!" + platforms);
      },
      error: (error) => {
        console.error('Failed to load platforms', error);
      }
    });
  }
}
