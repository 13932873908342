import { Component, OnInit,ViewChild } from '@angular/core';
import { MatDialog, MatPaginator} from '@angular/material';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap, delay } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { HttpClientService } from '../../service/httpclient.service'
import { MatSort, Sort } from '@angular/material/sort';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';

const API ="api/destination";
const API_FILE = 'api/file/'

@Component({
  selector: 'app-admin-destination',
  templateUrl: './admin-destination.component.html',
  styleUrls: ['./admin-destination.component.scss']
})
export class AdminDestinationComponent implements OnInit {
  
  file;
  dataSource:Array<any>=[];
  resultsLength = 100;
  displayedColumns: string[] = ['name','origin','notifications',  'status', 'insert', 'edit'];
  
  statusFilter="";
  nameFilter="";
  empty = false;
  nameSearch='';

  constructor(private http: HttpClientService,public dialog: MatDialog) { }
  
  ngOnInit() {
    this.find(false);
  }
  
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  find(sort) {
    merge(this.paginator.page)
      .pipe(
        startWith({}),
        delay(0),
        switchMap(() => {
          let params = new HttpParams()
          .set('status', this.statusFilter.toString())
          .set('name', this.nameFilter.toString())
          .set('pageIndex', this.paginator.pageIndex.toString()).set('pageSize', this.paginator.pageSize.toString());
          if(sort==true){
            params = params.set('sort', this.sort.active + "," + this.sort.direction);
          }
          if(this.nameSearch !=null){
            params = params.set('name', this.nameSearch);
          }
          return this.http.get(API+'/search/admin', params);
        }),
        map(data => {
          this.resultsLength = data.totalElements;
          return data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => {
        this.dataSource = data.content;
        if(this.dataSource.length == 0){
          this.empty = true;
        }else{
          this.empty = false;
        }
      });
  }

  onFileSelected(event, destinationId) {
    this.file = event.target.files[0]
    this.uploadFile(this.file, destinationId).subscribe(file => {
      console.log(file);
    });
  }

  uploadFile(file, destinationId) {
    let fileName = file.name;
    let format = file.name.split('.').pop();
    const formData = new FormData();
    formData.append("format", format);
    formData.append("file", file);
    formData.append("destinationId", destinationId);
    return this.http.postImg(API_FILE + 'point', formData);
  }

  announceSortChange(sortState: Sort) {
    this.find(true);
  }

  remove(id) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {id: id, type: 'destination', title: 'Eliminar destino', text: '¿Quieres eliminar este destino permanentemente?', mainButton: 'Eliminar'}
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.id){
        this.find(false);
      }
    });
  }

}
