import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { Platform } from '../models/Platform';
import { HttpClientService } from './httpclient.service';
const API_PLATFORM = `api/platforms`;
@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  private platformsSubject = new BehaviorSubject<Platform[]>([]);
  public platforms$ = this.platformsSubject.asObservable(); // Exponer como Observable para consumo externo

  constructor(private http: HttpClientService) {
    this.loadPlatforms();
  }

  private loadPlatforms(): void {
    this.http.get(API_PLATFORM).pipe(
      tap({
        next: platforms => {
          this.platformsSubject.next(platforms); // Actualiza BehaviorSubject con las nuevas plataformas
        },
        error: error => {
          console.error('Error loading platforms:', error);
        }
      })
    ).subscribe();
  }

  get platforms(): Platform[] {
    return this.platformsSubject.getValue(); // Acceso sincrónico al último valor de las plataformas
  }

  refreshPlatforms(): void {
    this.loadPlatforms(); // Método para recargar plataformas si es necesario
  }
}
