import { Component, Inject, Input, OnInit, SimpleChanges } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { HttpClientService } from 'src/app/service/httpclient.service';
const API_FILE: string = 'api/file/';
const API_COMMENTS:string = "api/comments/";
@Component({
  selector: 'app-comments-list',
  templateUrl: './list-comments.component.html',
  styleUrls: ['./list-comments.component.scss']
})

export class ListCommentsComponent implements OnInit {
  
  @Input() type!:string;
  @Input() objectId!:string;
  @Input() allObjects:boolean = false;
  @Input() reloadTrigger: boolean = false;
  comments!: any;
  isMore: boolean = true;
  initials!: string;
  imgURL!: string;
  avatarName!: string;

  constructor(private http: HttpClientService, @Inject(DOCUMENT)private document: Document) { }

  ngOnInit() {
   
    
    
  }

  find() {
    let params = new HttpParams()
    .set('objectId', this.objectId)
    .set('type', this.type)
    this.http.get(API_COMMENTS, params).subscribe(comments => {
      comments.reverse();
      //if(this.allObjects){
        this.comments = comments;
      // }else{
      //   this.comments = comments.slice(0,4);
      // }
      console.log("comentarios", comments)
      this.mapData();
    });
    
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['routes'] && this.comments) {
      this.comments.forEach((element, i) => {
        
        // if (element.user.imageURL != null && element.user.imageURL != '') {
        //   this.findImage(element.user.imageURL).pipe(
        //     catchError(err => {
        //       console.error(`Error al cargar la imagen: ${err.message}`);
        //       return of(null); 
        //     })
        //   ).subscribe(image => {
        //     if (image) {
        //       this.comments[i].user.avatar =  image.message;
        //     } else {
        //       this.comments[i].user.avatar = null;
        //     }
        //   });
        // }
         
      });
    }
    if (changes['objectId'] && this.objectId) {
      this.find();
      
    }
    if (changes['reloadTrigger']) {
      // console.log("cambia variable", this.reloadTrigger)
      this.find();
    }
  }

  mapData(){
    this.comments.forEach((element, i) => {
      // if(element.userPictureURL!=null && element.userPictureURL!=''){
      //   this.findImage(element.userPictureURL).subscribe(image=>{
      //     this.comments[i].avatar=image;
      //   });
      // }
    });
  }

  getInitials(name?) {
    
    if (!name) {
      this.initials = '';
    }
  
    const initials = name
      .split(' ')
      .map(part => part.charAt(0))
      .join('')
      .toUpperCase();

    return initials;
  }
}
