import { Component } from '@angular/core';
import { TokenService } from './service/token.service';
import { Router } from '@angular/router';
import { HttpClientService } from 'src/app/service/httpclient.service';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  RESET_URL: string = 'api/user/reset';

  constructor(private snackBar: MatSnackBar, 
    private http: HttpClientService, 
    public tokenService: TokenService, 
    private router: Router) { }

  title = 'mystreetbook-front';

  reset() {
    this.http.get(this.RESET_URL).subscribe(
        response => {
            this.openSnackBar("Le hemos enviado un enlace de restablecimiento a su bandeja de entrada de correo electrónico");
        },
        error => {
            console.error(error);
        }
    );
}

openSnackBar(message: string) {
    this.snackBar.open(message, "Cerrar", {
      duration: 4000,
      panelClass: ['success-snackbar'],
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
}
