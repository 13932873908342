import { Component, OnInit } from '@angular/core';
import { TokenService } from 'src/app/service/token.service';
import { HttpClientService } from 'src/app/service/httpclient.service';
import { Router, ActivatedRoute } from "@angular/router";
import { HttpParams } from '@angular/common/http';

const API_NOTIFICATION = 'api/notification'
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  from='';
  to='';
  userId!:string;
  notifications!:any[];
  destinationId!:string;
  constructor( private http:HttpClientService, public tokenService:TokenService,private activeRoute: ActivatedRoute, public router: Router) { }

  ngOnInit() {
    this.userId = this.tokenService.getUserId();
    this.activeRoute.paramMap.subscribe(params => {
 
      if (params.get('destinationId') != null) {
        this.destinationId= params.get('destinationId');
      }
    });
    this.find();
  }

  find(){

    let params = new HttpParams()
      .set('from', this.from.toString())
      .set('to', this.to.toString())
    this.http.get(`${API_NOTIFICATION}`, params).subscribe(response=>{
      this.notifications = response;
    })
  }

}
