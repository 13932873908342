import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../../service/httpclient.service'
import { Router, ActivatedRoute } from "@angular/router";
import { TokenService } from '../../service/token.service';
import { HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { TopicService } from 'src/app/service/topic.service';

const API_POINT = "api/point/"
const API_TOPIC = 'api/topic/'
const API_DESTINATION = "api/destination/";
@Component({
  selector: 'app-point',
  templateUrl: './point.component.html',
  styleUrls: ['./point.component.scss']
})
export class PointComponent implements OnInit {
  platformNames = new Set<string>();
  platformGroups;
  destinationId;
  destination;
  points;
  topics;
  searchName = '';
  topicsFilter = [];
  onlyEvent = false;
  onlyVisible = false;
  localTopics;
  topicNamefiltered;
  topicfiltered;
  constructor(private http: HttpClientService,
    public tokenService: TokenService,private topicService:TopicService,
    private activeRoute: ActivatedRoute,
    public router: Router, public dialog: MatDialog) { }


  ngOnInit() {
    this.destination = this.tokenService.getDestination();
    this.activeRoute.paramMap.subscribe(params => {
      if (params.get('id') != null) {
        this.destinationId = params.get('id');
        this.findPoints();
        this.findDestination();
      }
    });
  }

  topicCheck(object: any) {
    this.topicsSelected(object.event, object.topic);
  }

  topicsSelected(event: any, topic) {
    if (event.checked) {
      if (this.topicsFilter == null) {
        this.topicsFilter = [];
      }
      this.topicsFilter.push(topic);
    } else {
      this.topicsFilter = this.topicsFilter.filter(t => t != topic);
    }
  }


  findDestination() {
    this.http.get(API_DESTINATION + this.destinationId).subscribe(
      response => {
        this.destination = response;
        if (response.platforms) {
          // 3. Recorrer cada plataforma y añadir su name al set
          response.platforms.forEach(platform => {
            this.platformNames.add(platform.name);
          });
        }
        this.findTopics();
      }
    );
  }


  findPoints() {
    let params = new HttpParams()
      .set('visible', this.onlyVisible.toString())
      .set('event', this.onlyEvent.toString())
      .set('name', this.searchName.toString())
      .set('topics', this.topicsFilter.toString())
    this.http.get(API_POINT + 'destination/' + this.destinationId, params).subscribe(result => {
      this.points = result;
    });
  }

  ckeckFilter(event, topicId) {
    console.log(topicId);
    if (event) {
      this.topicsFilter.push(topicId);
    } else {
      this.topicsFilter = this.topicsFilter.filter(id => id != topicId);
    }
    console.log(this.topicsFilter);

  }

  remove(id) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {id: id, type: 'point', title: 'Eliminar punto', text: '¿Quieres eliminar este punto permanentemente?', mainButton: 'Eliminar'}
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.id){
        this.findPoints();
      }
    });
  }


  // findTopics() {
  //   let idTopicsDestination = this.destination.topics.map(topic => topic.topic);
  //   this.http.get(API_TOPIC).subscribe(result => {
  //     let topics = result.filter(topic => idTopicsDestination.includes(topic.id)).filter(topic => topic.local == null || topic.local == false);
  //     this.localTopics = result.filter(topic => idTopicsDestination.includes(topic.id)).filter(topic => topic.local == true).map(topic => {
  //       return { topic: topic, value: false }
  //     });
  //     let parents = topics.filter(topic => topic.parentId == null)
  //       .map(topic => {
  //         return { parent: topic, kids: [] };
  //       });
  //     parents.forEach(topic => {
  //       topic.kids = topics.filter(topicChill => topicChill.parentId == topic.parent.id);
  //       console.log(topic)
  //     })

  //     this.topics = parents;
  //   })
  // }

  findTopics() {
    let idTopicsDestination = this.destination.topics.map(topic => topic.topic);

    this.topicService.getAllTopics().subscribe(result => {
      let allTopics = result;
      this.localTopics = result.filter(t => t.local === true);
      console.log("LOCALTOPICS:", this.localTopics);
   
      const nonLocalTopics = allTopics.filter(t => !t.local === true);
      this.platformGroups = this.topicService.groupByPlatformWithHierarchy(nonLocalTopics);
      let topics = result;
      let parents = topics.filter(topic => topic.parentId == null && !topic.local)
        .map(topic => {
          return { parent: topic, value: false, kids: [] };
        });
      parents.forEach(topic => {
        topic.kids = result.filter(topic => topic.local == null || topic.local == false)
          .filter(topic => !topic.local)
          .filter(topicChill => topicChill.parentId == topic.parent.id)
          .map(topic => { return { topic: topic, value: false } });
      });
      this.platformGroups = this.platformGroups.filter(group => {
        return this.platformNames.has(group.platform.name);
      });
      this.topics = parents;
    })
  }

}
