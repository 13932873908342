import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpClientService } from '../service/httpclient.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
const header = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private authURL = 'api/auth/';


  constructor(private httpClientService: HttpClientService) { }

  public signUp(user): Observable<any> {
    return this.httpClientService.post(this.authURL + 'registry', user);
  }

  public validateRegistryToken(id: String, token: String): Observable<any> {
    return this.httpClientService.get(this.authURL + id + "/validate/" + token);
  }

  public login(user): Observable<any> {
    return this.httpClientService.post(this.authURL + "login", user);
  }

}