import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../../../service/httpclient.service'
import { Router, ActivatedRoute } from "@angular/router";
import { TokenService } from '../../../service/token.service';

const API = "api/user/destination/"
const API_DESTINATION = "api/destination/"
@Component({
  selector: 'app-destination-detail',
  templateUrl: './destination-detail.component.html',
  styleUrls: ['./destination-detail.component.scss']
})
export class DestinationDetailComponent implements OnInit {
  destinationId;
  destination;
  completeDestination;

  constructor(private http: HttpClientService,
    public tokenService: TokenService,
    private activeRoute: ActivatedRoute,
    public router: Router) { }

  ngOnInit() {
    this.destination = this.tokenService.getDestination();
    console.log('*******', this.destination);
    this.activeRoute.paramMap.subscribe(params => {
      if (params.get('id') != null) {
        this.destinationId = params.get('id');
        this.findDestination();
      }
    });
  }

  findDestination(){
    this.http.get(API_DESTINATION+this.destinationId).subscribe(response=>this.completeDestination=response);
  }
}
