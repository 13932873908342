import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Platform } from '../models/Platform';
import { environment } from 'src/environments/environment';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('authToken');
    const clonedReq = req.clone({
      headers: req.headers
        .set('Authorization', token ? `Bearer ${token}` : '')
        .set('platform', environment.platform)
    });

    return next.handle(clonedReq);
  }
}
