import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { MaterialModule } from './material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { RegistryComponent } from './auth/registry/registry.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BasicAuthHtppInterceptorService } from './service/basicAuthHttpInterceptor.service';
import { DestinationComponent } from './client/destination/destination.component';
import { PointComponent } from './client/point/point.component';
import { RouteComponent } from './client/route/route.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RecoveryComponent } from './auth/recovery/recovery.component';
import { AdminDestinationComponent } from './admin/admin-destination/admin-destination.component';
import { AdminClientComponent } from './admin/admin-client/admin-client.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { DestinationDetailComponent } from './client/destination/destination-detail/destination-detail.component';
import { PointDetailComponent } from './client/point/point-detail/point-detail.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { RouteDetailComponent } from './client/route/route-detail/route-detail.component';
import { AdminTopicComponent } from './admin/admin-topic/admin-topic.component';
import { ResetComponent } from './auth/reset/reset.component';
import { AdminDestinationDetailComponent } from './admin/admin-destination/admin-destination-detail/admin-destination-detail.component';
import { AdminClientDetailComponent } from './admin/admin-client/admin-client-detail/admin-client-detail.component';
import { AdminTopicDetailComponent } from './admin/admin-topic/admin-topic-detail/admin-topic-detail.component';
import { AdminPointComponent } from './admin/admin-point/admin-point.component';
import { AdminPointDetailComponent } from './admin/admin-point/admin-point-detail/admin-point-detail.component';
import { AdminRouteComponent } from './admin/admin-route/admin-route.component';
import { AdminRouteDetailComponent } from './admin/admin-route/admin-route-detail/admin-route-detail.component';
import { HeaderComponent } from './components/header/header.component';
import { NotificationsComponent } from './client/notifications/notifications.component';
import { NotificationsDetailComponent } from './client/notifications/notifications-detail/notifications-detail.component';
import { AdminNotificationComponent } from './admin/admin-notification/admin-notification.component';
import { TopicFilterListComponent } from './components/topic-filter-list/topic-filter-list.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ListRoutesComponent } from './components/list-routes/list-routes.component';
import { ListCommentsComponent } from './components/list-comments/list-comments.component';
import { AuthInterceptor } from './service/AuthInterceptor';
import { FilterNameAddOiuntToRoute } from './pipe/FilterNameAddOiuntToRoute.pipe';
import { ErrorSuccessPopupComponent } from './components/error-success-popup/error-success-popup.component';
import { FileService } from './service/file.service';
import { SpinnerComponent } from './components/spinner/spinner.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegistryComponent,
    DestinationComponent,
    PointComponent,
    RouteComponent,
    RecoveryComponent,
    AdminDestinationComponent,
    AdminClientComponent,
    AdminDashboardComponent,
    DestinationDetailComponent,
    PointDetailComponent,
    LogoutComponent,
    RouteDetailComponent,
    AdminTopicComponent,
    ResetComponent,
    AdminDestinationDetailComponent,
    AdminClientDetailComponent,
    AdminTopicDetailComponent,
    AdminPointComponent,
    AdminPointDetailComponent,
    AdminRouteComponent, HeaderComponent,
    AdminRouteDetailComponent,FilterNameAddOiuntToRoute,
    NotificationsComponent,
    NotificationsDetailComponent,
    AdminNotificationComponent,
    TopicFilterListComponent,
    ConfirmDialogComponent, ListRoutesComponent, ListCommentsComponent, ErrorSuccessPopupComponent, SpinnerComponent
  ],
  entryComponents: [ConfirmDialogComponent, ErrorSuccessPopupComponent],
  imports: [
    BrowserModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS, useClass: BasicAuthHtppInterceptorService, multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
