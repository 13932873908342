import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog, MatPaginator } from '@angular/material';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap, delay } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { HttpClientService } from '../../service/httpclient.service'
import { MatSort, Sort } from '@angular/material/sort';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';

const API_TOPIC = 'api/topic/';
@Component({
  selector: 'app-admin-topic',
  templateUrl: './admin-topic.component.html',
  styleUrls: ['./admin-topic.component.scss']
})
export class AdminTopicComponent implements OnInit {
  dataSource: Array<any> = [];
  resultsLength = 200;
  displayedColumns: string[] = ['name', 'parent', 'edit', 'remove'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  empty = false;
  nameSearch='';

  constructor(
    private http: HttpClientService, public dialog: MatDialog
  ) { }
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  ngOnInit() {
    this.find(false);
  }

  find(sort) {
    merge(this.paginator.page)
      .pipe(
        startWith({}),
        delay(0),
        switchMap(() => {
          let params;
          if (sort == true) {
            params = new HttpParams()
              .set('sort', this.sort.active + "," + this.sort.direction)
              .set('pageIndex', this.paginator.pageIndex.toString()).set('pageSize', this.paginator.pageSize.toString());
          } else {
            params = new HttpParams()
              .set('pageIndex', this.paginator.pageIndex.toString()).set('pageSize', this.paginator.pageSize.toString());
          }
          if(this.nameSearch !=null){
            params = params.set('name', this.nameSearch);
          }
          return this.http.get(API_TOPIC + 'search', params);
        }),
        map(data => {
          console.log(data);
          this.resultsLength = data.totalElements;
          return data;
        }),
        catchError(() => {
          return observableOf([]);
        })
        ).subscribe(data => {
          console.log(data.content);
          this.dataSource = data.content;
          this.dataSource.map(t=>{
            if(t.parentId!=null && t.parentId!="-1"){
              t.parentId = this.dataSource.filter(tp=>tp.id===t.parentId)[0].name;
            }else if(t.parentId=="-1"){
              t.parentId =null;
            }
          })
          if(this.dataSource.length == 0){
            this.empty = true;
          }else{
            this.empty = false;
          }
      });
  }

  remove(id) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {id: id, type: 'topic', title: 'Eliminar categoría', text: '¿Quieres eliminar esta categoría permanentemente?', mainButton: 'Eliminar'}
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.id){
        this.find(false);
      }
    });
  }

  announceSortChange(sortState: Sort) {
    this.find(true);

  }

}
