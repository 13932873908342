import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator} from '@angular/material';
import { MatSort, Sort } from '@angular/material/sort'
import { catchError, map, startWith, switchMap, delay } from 'rxjs/operators';
import { merge, Observable, of as observableOf } from 'rxjs';
import { HttpClientService } from '../../service/httpclient.service'
import { HttpParams } from '@angular/common/http';

const API_NOTIFICATIONS = 'api/notification';

@Component({
  selector: 'app-admin-notification',
  templateUrl: './admin-notification.component.html',
  styleUrls: ['./admin-notification.component.scss']
})
export class AdminNotificationComponent implements OnInit {
  displayedColumns: string[] = ['createDate', 'title', 'message', 'status',  'edit', 'delete'];

  notifications!:any[];
  clientSelected:string="";
  resultsLength = 100;
  empty = false;
  nameSearch='';
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private http: HttpClientService) { }

  ngOnInit() {
    this.find();
  }


  find() {
    merge(this.paginator.page)
      .pipe(
        startWith({}),
        delay(0),
        switchMap(() => {
          let params = new HttpParams()
          .set('client', this.clientSelected.toString())
          .set('pageIndex', this.paginator.pageIndex.toString())
          .set('pageSize', this.paginator.pageSize.toString());
          if(this.nameSearch !=null){
            params = params.set('title', this.nameSearch);
          }

          return this.http.get(API_NOTIFICATIONS+'/search', params);
        }),
        map(data => {
          this.resultsLength = data.totalElements;
          return data;
        })
      ).subscribe(data => {
        this.notifications = data.content;
        console.log(this.notifications)
        if(this.notifications.length == 0){
          this.empty = true;
        }else{
          this.empty = false;
        }
      });
  }

  delete(id){
    this.http.deleteById(API_NOTIFICATIONS +'/'+id).subscribe(result => this.find());
  }
}
