import { Component, OnInit,ViewChild,AfterViewInit } from '@angular/core';
import { HttpClientService } from '../../service/httpclient.service';
import { MatDialog, MatPaginator} from '@angular/material';
import { merge,  of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap, delay } from 'rxjs/operators';
import { MatSort, Sort } from '@angular/material/sort';
import { HttpParams } from '@angular/common/http';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';



const API_USER = 'api/user/';

@Component({
  selector: 'app-admin-client',
  templateUrl: './admin-client.component.html',
  styleUrls: ['./admin-client.component.scss']
})
export class AdminClientComponent implements OnInit {

  dataSource:Array<any>=[];
  resultsLength = 0;
  displayedColumns: string[] = ['email',  'name', 'facebook', 'apple','google', 'createdDate', 'role', 'status', 'edit', 'delete'];
  private searchSubject = new Subject<string>();

  name;
  lasName;
  email;
  clients=[];
  statusFilter="";
  roleFilter=[];
  nameFilter="";
  emailFilter="";
  empty = false;
  nameSearch='';

  constructor(
    private http: HttpClientService, public dialog: MatDialog
  ) { }
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  ngOnInit(): void {
    // 2) Te suscribes al Subject con debounceTime y distinctUntilChanged
    this.searchSubject
      .pipe(
        debounceTime(400),      // Espera 400ms tras la última tecla
        distinctUntilChanged()   // Solo emite si el texto cambió
      )
      .subscribe(() => {
        // 3) Aquí simplemente llamas al método `find(false)`,
        //    sin modificar su implementación interna
        this.find(false);
      });
  }

  ngAfterViewInit() {
    this.find(false);
  }

  onSearchChange(value: string) {
    // Guardas el valor en la variable local (por si lo requieres en `find(...)`)
    this.nameSearch = value;
    // Emitimos en el Subject para que aplique el debounce
    this.searchSubject.next(value);
  }


  find(sort) {
    merge(this.paginator.page)
      .pipe(
        startWith({}),
        delay(0),
        switchMap(() => {
          let params = new HttpParams()
          .set('status', this.statusFilter.toString())
          // .set('name', this.nameFilter.toString())
          // .set('email', this.emailFilter.toString())
          .set('roles', this.roleFilter.toString())
          .set('pageIndex', this.paginator.pageIndex.toString()).set('pageSize', this.paginator.pageSize.toString());
          if(sort==true){
            params = params.set('sort', this.sort.active + "," + this.sort.direction);
          }
          if (this.nameSearch && this.nameSearch.trim() !== '') {
            params = params.set('name', this.nameSearch);
            if(this.emailFilter==null || this.emailFilter==''){
              params = params.set('email', this.nameSearch);
            }
          }
          return this.http.get(API_USER+'search', params);
        }),
        map(data => {
          this.resultsLength = data.totalElements;
          return data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => {
        this.dataSource = data.content;
        if(this.dataSource.length == 0){
          this.empty = true;
        }else{
          this.empty = false;
        }
        
      });
  }

  remove(id) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {id: id, type: 'user', title: 'Eliminar usuario', text: '¿Quieres eliminar este usuario permanentemente?', mainButton: 'Eliminar'}
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.id){
        this.find(false);
      }
    });
  }

  announceSortChange(sortState: Sort) {
    this.find(true);
  }
}
