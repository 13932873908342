import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClientService } from 'src/app/service/httpclient.service';
import { MatSnackBar } from '@angular/material';

const RESET_URL: string = 'api/user/reset';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {
  routeLinks: any[];
  activeLinkIndex = -1;
  constructor(private snackBar: MatSnackBar,
    private http: HttpClientService, private router: Router) {
    this.routeLinks = [
      {
        label: 'Usuarios',
        link: '/admin/user',
        index: 0,
        icon: '../assets/img/icon-user.svg',
        iconFocus: '../assets/img/icon-user__color.svg'
      }, {
        label: 'Destinos',
        link: '/admin/destination',
        index: 1,
        icon: '../assets/img/icon-loc.svg',
        iconFocus: '../assets/img/icon-loc__color.svg'
      }, {
        label: 'Puntos',
        link: '/admin/point',
        index: 2,
        icon: '../assets/img/icon-marker-empty.svg',
        iconFocus: '../assets/img/icon-marker-empty__color.svg'
      }, {
        label: 'Rutas',
        link: '/admin/route',
        index: 3,
        icon: '../assets/img/icon-small-rute.svg',
        iconFocus: '../assets/img/icon-small-rute__color.svg'
      }, {
        label: 'Categorías',
        link: '/admin/topic',
        index: 4,
        icon: '../assets/img/icon-marker-full.svg',
        iconFocus: '../assets/img/icon-marker-full__color.svg'
      }, {
        label: 'Notificaciones',
        link: '/admin/notification',
        index: 5,
        icon: '../assets/img/icon-comment.svg',
        iconFocus: '../assets/img/icon-comment__color.svg'
      }
    ];
  }

  ngOnInit(): void {
    this.router.events.subscribe((res) => {
      this.activeLinkIndex = this.routeLinks.indexOf(this.routeLinks.find(tab => this.router.url.includes(tab.link)));
    });
  }
  reset() {
    this.http.get(RESET_URL).subscribe(
      response => {
        this.openSnackBar("Le hemos enviado un enlace de restablecimiento a su bandeja de entrada de correo electrónico");
      },
      error => {
        console.error(error);
      }
    );
  }
  openSnackBar(message: string) {
    this.snackBar.open(message, "Cerrar", {
      duration: 4000,
      panelClass: ['success-snackbar'],
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
}
