import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../../../service/httpclient.service';
import { Router, ActivatedRoute } from "@angular/router";
import { User } from 'src/app/models/User';

const API_ROLE = 'api/role/';
const API_USER = 'api/user/';
const API_DESTINATION = 'api/destination/';

@Component({
  selector: 'app-admin-client-detail',
  templateUrl: './admin-client-detail.component.html',
  styleUrls: ['./admin-client-detail.component.scss']
})
export class AdminClientDetailComponent implements OnInit {

  userId: string;
  user: User = new User(); // Inicializamos user con un objeto User vacío
  roles=[];
  destinations:any[]=[];
  
  
  constructor(private router: Router,
    private activeRoute: ActivatedRoute,
    private http: HttpClientService
  ) { }

  ngOnInit() {
    this.findAllRoles();
    this.activeRoute.paramMap.subscribe(params => {
      if (params.get('id') != null) {
        this.userId = params.get('id');
        this.findUser();        
      }
    });
  }

  findUser(){
    this.http.get(API_USER+this.userId).subscribe(
      response => {
        this.user = response;
        if (Array.from(this.user.role).some(r => r.name === 'CLIENT')) {
          this.findDestinations();
        }
      }
    );
  }

  findAllRoles(){
    this.http.get(API_ROLE).subscribe(
      response => {
        this.roles = Array.from(response);
      }
    );
  }

  findDestinations(){
    this.http.get(API_DESTINATION+'user/'+this.user.id).subscribe(
      response => {
        this.destinations = response;
      }
    );
  }

  save(){
      this.http.post(API_USER, this.user).subscribe(
        response => {
          this.goBack();
        }
      );
  }

  goBack() {
    this.router.navigate(['/admin/user/']);
  }

}
