import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../../service/httpclient.service';
import { TokenService } from '../../service/token.service';
import { Router } from '@angular/router';

const API_DESTINATION = 'api/destination/user/';

@Component({
  selector: 'app-destination',
  templateUrl: './destination.component.html',
  styleUrls: ['./destination.component.scss']
})
export class DestinationComponent implements OnInit {
  destinations: any[];

  constructor(
    private httpClientService: HttpClientService,
    public tokenService: TokenService, private router: Router
  ) { }

  ngOnInit() {
    this.findDestination();
  }

  findDestination(){
    this.httpClientService.get(API_DESTINATION).subscribe(
      response => {     
        this.destinations = response.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });      }
    );
  }

  goDetail(destination) {
    this.tokenService.setDestination(destination.name);
    this.router.navigate(['/destination/detail/'+destination.id]);
  }

}
