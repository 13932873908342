import { Component, OnInit } from '@angular/core';
import { TokenService } from '../../service/token.service';
import { Router } from '@angular/router';
import { HttpClientService } from '../../service/httpclient.service'
import { AuthService } from '../../service/auth.service';
import { FormGroup, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isLogged = false;
  isLoginFail = false;
  roles: string[] = [];
  form: any = {};
  errorMsg = '';
  login: FormGroup;
  constructor(
    private snackBar: MatSnackBar,
    private authService: AuthService, private http: HttpClientService, private tokenService: TokenService, private router: Router) { }


  ngOnInit() {
    this.login = new FormGroup({
      userName: new FormControl(''),
      password: new FormControl(''),
    });
    this.tokenService.logOut();
    if (this.tokenService.getToken()) {
      this.isLogged = true;
      this.isLoginFail = false;
      this.roles = this.tokenService.getAuthorities();
    }
  }

  onLogin(): void {
    const user = {
      ...this.login.value,
    }
    this.authService.login(user).subscribe(data => {
      console.log("user", data)
      this.tokenService.setToken(data.token);
      this.tokenService.setUserName(data.userName);
      this.tokenService.setAuthorities(data.authorities);

      this.isLogged = true;
      this.isLoginFail = false;
      this.roles = this.tokenService.getAuthorities();
      if(this.roles.includes("ADMIN")){
        this.go("/admin")
      }else if(this.roles.includes("CLIENT")){
        this.go("/destination")
      }else {
        this.go("/logout")
      }
    },
      (err: any) => {
        this.snackBar.open('Contraseña o usuario incorrectos.', 'Cerrar', {
          duration: 3000,
        });
        this.isLogged = false;
        this.isLoginFail = true;
        // this.errorMsg = err.error.message;
        console.log(err);
      }
    );
  }

  go(path: string) {
    this.router.navigate([path]);
  }

}
