import { Injectable } from '@angular/core';
import { HttpClientService } from './httpclient.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const API_TOPIC = 'api/topic/';

@Injectable({
  providedIn: 'root'
})
export class TopicService {
  public allTopics:any[]=[];
  constructor(private http: HttpClientService) { }

  public getAllTopics(){
    return this.allTopics;
  }
  public serAllTopics(topics){
    this.allTopics;
  }

  public findTopics(): Observable<any[]> {
    return this.http.get(API_TOPIC); 
  }

  public getTopicsByIds(ids: string[]): Observable<any[]> {
    return this.findTopics().pipe(
      map(topics => topics.filter(topic => ids.includes(topic.id)))
    );
  }
  
  public findAllTopics():Observable<any[any]>  {
    return this.http.get(API_TOPIC);
  }

  public getGroupTopics(): Observable<any[]> {
    return this.findAllTopics().pipe(
      map(result => {
        this.allTopics = result;
        let topics = result;
        let parents = topics.filter(topic => topic.parentId == null && !topic.local)
          .map(topic => {
            return { parent: topic, value: false, kids: [] };
          });
        parents.forEach(topic => {
          topic.kids = topics.filter(topicChill => topicChill.parentId == topic.parent.id && (!topicChill.local || topicChill.local == false))
                            .map(topicChill => { return { topic: topicChill, value: false } });
        });
        return parents; // Devolver los datos procesados
      })
    );
  }

 
}
