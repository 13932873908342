import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  // Se utiliza un BehaviorSubject para mantener el estado del spinner (visible o no).
  private spinnerSubject = new BehaviorSubject<boolean>(false);

  // Observable al que los componentes se suscribirán.
  spinner$ = this.spinnerSubject.asObservable();

  // Métodos para mostrar y ocultar el spinner.
  showSpinner() {
    this.spinnerSubject.next(true);
  }

  hideSpinner() {
    this.spinnerSubject.next(false);
  }
}
