import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClientService } from '../../service/httpclient.service';
import { MatDialog, MatPaginator } from '@angular/material';
import { catchError, map, startWith, switchMap, delay } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged} from 'rxjs/operators';

import { Subject,merge, Observable, of as observableOf } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { MatSort, Sort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSuccessPopupComponent } from 'src/app/components/error-success-popup/error-success-popup.component';
import { PlatformService } from 'src/app/service/Platform.service';
import { Platform } from 'src/app/models/Platform';
import { TopicService } from 'src/app/service/topic.service';

const API = 'api/point/';
const API_TOPIC = 'api/topic/'
const API_DESTINATION = 'api/destination/'
const API_FILE = 'api/file/'
@Component({
  selector: 'app-admin-point',
  templateUrl: './admin-point.component.html',
  styleUrls: ['./admin-point.component.scss']
})
export class AdminPointComponent implements OnInit {
  platformGroups;
  isLoading: boolean = false;  // Controla el spinner
  errorMessages: { pointName: string, fieldName: string, errorMessage: string, additionalDetails?: string }[] = [];
  successMessages: string[] = [];
  platforms!: any;
  selectedPlatforms: Set<String> = new Set<String>();
  platformOptions = Object.values(Platform);
  statusFilter = "";
  nameFilter = "";
  dataSource: Array<any> = [];
  resultsLength = 100;
  displayedColumns: string[] = ['name','destination', 'popularity', 'web',  'images', 'visible', 'accesible', 'event', 'private', 'edit', 'delete'];
  topics = [];
  topicsFilter = [];
  localTopics=[];
  destinations = [];
  destinationFilter = [];
  empty = false;
  nameSearch = '';
  private searchSubject = new Subject<void>();

  filters={topics:null, images:null, popularity:null, coordinates:null, description:null, onlyEvents:null, price:null, openings:null}
  constructor(private activeRoute: ActivatedRoute,private snackBar: MatSnackBar,  
    private platformService: PlatformService, private topicService:TopicService,
    private http: HttpClientService, private router: Router, public dialog: MatDialog
  ) { }
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  ngOnInit() {
    this.searchSubject
    .pipe(
      debounceTime(500),
      // distinctUntilChanged() // opcional si quieres ignorar valores repetidos
    )
    .subscribe((sort) => {
      this.find(sort);   // Aquí llamamos a la lógica real
    });
    this.findPlatform();
    this.activeRoute.queryParams.subscribe(queryParams => {
      this.statusFilter = queryParams['status'] || '';
      this.nameFilter = queryParams['name'] || '';
      //this.topicsSelected = queryParams['topics'] ? queryParams['topics'].split(',') : [];
      this.destinationFilter = queryParams['destinations'] ? queryParams['destinations'].split(',') : [];
      this.find(false);
      this.findTopics();
      this.findDestination();
    });

    // this.find(false);
    // this.findTopics();
    // this.findDestination();
  }
  
  onNameSearchChange() {
    this.searchSubject.next();
  }
  find(sort) {
    const filtersJson = JSON.stringify(this.filters);
    const platformsArray = Array.from(this.selectedPlatforms); 

    merge(this.paginator.page)
      .pipe(
        startWith({}),
        delay(0),
        switchMap(() => {
          let params = new HttpParams()
            .set('status', this.statusFilter.toString())
            .set('name', this.nameFilter.toString())
            .set('destinations', this.destinationFilter.toString())
            .set('filters', filtersJson)
            .set('platforms', platformsArray.toString())
            .set('topics', this.topicsFilter.toString())
            .set('pageIndex', this.paginator.pageIndex.toString()).set('pageSize', this.paginator.pageSize.toString());
          if (sort == true) {
            params = params.set('sort', this.sort.active + "," + this.sort.direction);
          }
          if (this.nameSearch != null) {
            params = params.set('name', this.nameSearch);
          }
          return this.http.get(API + 'admin/search', params);
        }),
        map(data => {
          this.resultsLength = data.totalElements;
          return data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => {
        data.content.forEach(poi=>{
          if(poi.destinations!=null && poi.destinations.length>0){
            let destination = this.destinations.find(des=>des.id===poi.destinations[0]);
            poi.destinationName= destination.name;
          }
        })
        this.dataSource = data.content;
        if (this.dataSource!=null && this.dataSource.length == 0) {
          this.empty = true;
        } else {
          this.empty = false;
        }
      });
  }

  findTopics() {
    this.topicService.getAllTopics().subscribe(result => {
      let allTopics = result;
      this.localTopics = result.filter(t => t.local === true);
      console.log("LOCALTOPICS:", this.localTopics);
   
      const nonLocalTopics = allTopics.filter(t => !t.local === true);
      this.platformGroups = this.topicService.groupByPlatformWithHierarchy(nonLocalTopics);
      let topics = result;
      let parents = topics.filter(topic => topic.parentId == null && !topic.local)
        .map(topic => {
          return { parent: topic, value: false, kids: [] };
        });
      parents.forEach(topic => {
        topic.kids = result.filter(topic => topic.local == null || topic.local == false)
          .filter(topic => !topic.local)
          .filter(topicChill => topicChill.parentId == topic.parent.id)
          .map(topic => { return { topic: topic, value: false } });
      });

      this.topics = parents;
    })
  }

  findDestination() {
    this.http.get(API_DESTINATION).subscribe(result => {
      // Asegúrate de que result es un array antes de intentar ordenarlo
      if (Array.isArray(result)) {
        this.destinations = result;
      } else {
        this.destinations = []; // Asegúrate de manejar el caso en que no es un array
      }
    });
  }

  topicCheck(object: any) {
    this.topicsSelected(object.event, object.topic);
  }

  topicsSelected(event: any, topic: any) {
    if (event.checked) {
      if (this.topicsFilter == null) {
        this.topicsFilter = [];
      }
      this.topicsFilter.push(topic);
    } else {
      this.topicsFilter = this.topicsFilter.filter(t => t != topic);
    }
  }

  remove(id) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { id: id, type: 'point', title: 'Eliminar punto', text: '¿Quieres eliminar este punto permanentemente?', mainButton: 'Eliminar' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.id) {
        this.find(false);
      }
    });

  }
  announceSortChange(sortState: Sort) {
    console.log("NUEVA BÚSQUEDA");
    this.find(true);
  }

  navigateToDetail(pointId: string, event: MouseEvent) {
    if (event.button === 0) { // Solo manejar clic izquierdo
      event.preventDefault(); // Prevenir la acción por defecto del enlace
      this.router.navigate(['/admin/point/detail/', pointId], {
        queryParams: {
          status: this.statusFilter,
          name: this.nameFilter,
          //  topics: this.topicsSelected.toString(),
          destinations: this.destinationFilter.join(',')
        }
      });
    }
  }

  showFeedback() {
    if (this.errorMessages.length > 0) {
      console.log('Errores: ', this.errorMessages);
    } else {
      console.log('Éxito: ', this.successMessages);
    }
  }

  uploadFile(files: FileList | null): void {
    this.isLoading = true;  // Muestra el spinner y bloquea la página

    if (files && files.length > 0) {
      const file = files[0];
      const formData = new FormData();
      const extension = this.getFileExtension(file.name);

      formData.append('file', file);
      formData.append('format', extension); // or 'xlsx' based on your actual format
      formData.append('destinationId', 'your_destination_id');

      this.http.postImg(`${API_FILE}points`, formData).subscribe(
        (response: any) => {
          this.isLoading = false;  // Ocultar spinner
          // this.successMessages = response.success;
          // this.errorMessages = response.errors || [];

          const dialogRef = this.dialog.open(ErrorSuccessPopupComponent, {
            data: {
              success: response.success,
              errors: response.errors || []
            }
          });
          // this.showFeedback();
        },
        (error) => {
          this.isLoading = false;  // Ocultar spinner
          console.error('Error uploading file', error);
          this.snackBar.open('Error al subir el archivo', 'Cerrar', {
            duration: 3000,
          });
        }
      );
       
    }
  }

  getFileExtension(filename: string): string {
    return filename.split('.').pop() || '';
  }

  onCheckboxChange(platform: Platform, isChecked: boolean) {
    if (isChecked) {
      this.selectedPlatforms.add(platform.name);
    } else {
      this.selectedPlatforms.delete(platform.name);
    }
  }
  isPlatformSelected(platform: Platform): boolean {
    return this.selectedPlatforms.has(platform.name);
  }

  findPlatform(){
    this.platformService.platforms$.subscribe({
      next: (platforms) => {
        this.platformOptions = platforms;
        this.platforms = this.platformOptions;
        console.log("Platforms!" + platforms);
      },
      error: (error) => {
        console.error('Failed to load platforms', error);
      }
    });
  }
}
